import React, { useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../Services/API";
import useGlobalStore from "../../Store/Store";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const socket = useGlobalStore((state) => state.socket);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    API.post("/subscribers", { email })
      .then(() => {
        toast.success("Subscribed Successfully");
        setEmail("");
        socket.emit("sendNotification", "A New Subscriber has been Created!");
      })
      .catch((error) => {
        console.error("Error occurred while adding subscriber:", error);
        toast.error("Failed to subscribe");
      });
  };

  return (
    <div className="newsletter">
      <section id="newsletter" className="py-5">
        <div className="container">
          <div className="newsletter-details d-flex align-items-center justify-content-between">
            <div className="title text-center">
              <h3 className="position-relative d-inline-block ms-4 heading-h2">
                Subscribe Our Newsletter
              </h3>
            </div>

            <form onSubmit={handleSubmit} className="input-group mb-3 mt-3">
              <input
                type="email"
                className="form-control"
                placeholder="Enter Your Email ..."
                value={email}
                onChange={handleInputChange}
              />
              <button className="btn btn-primary" type="submit">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Newsletter;
