import { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Navigation from "../../Components/Navigation/Navigation";
import Newsletter from "../../Components/Newsletter/Newsletter";

const OrderTracking = () => {
  const [orderID, setOrderID] = useState("");
  const [email, setEmail] = useState("");
  const [trackingInfo, setTrackingInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleOrderIDChange = (event) => {
    setOrderID(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Replace this with your actual API call to fetch tracking information
    try {
      const response = await fetch(
        `your-api-endpoint/order/${orderID}?email=${email}`
      );
      const data = await response.json();
      setTrackingInfo(data);
    } catch (error) {
      console.error("Error fetching tracking information:", error);
    }

    setIsLoading(false);
  };

  return (
    <div>
      <Navigation />
      <div className="order-tracking row d-flex align-items-center justify-content-center">
        <div className="col-lg-4">
          <h2 className="text-center mb-4">Track Your Order</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="orderID">Order ID:</label>
              <input
                className="form-control"
                type="text"
                id="orderID"
                value={orderID}
                onChange={handleOrderIDChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                className="form-control"
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <div className="d-grid mt-4">
              <button
                type="submit"
                className="btn btn-danger d-md-block"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Track Order"}
              </button>
            </div>
          </form>
          {trackingInfo && (
            <div className="tracking-info">
              <h3>Order Tracking Details</h3>
              {/* Display tracking information here */}
            </div>
          )}
        </div>
      </div>

      <Newsletter />
      <Footer />
    </div>
  );
};

export default OrderTracking;
