import React, { useState } from "react";
import InputRange from "react-input-range";
import "./price-filter.scss";
import "react-input-range/lib/css/index.css";

const PriceFilter = ({ minPrice, maxPrice, onFilterChange }) => {
  const [rangeValue, setRangeValue] = useState({
    min: minPrice,
    max: maxPrice,
  });

  const handleRangeChange = (value) => {
    setRangeValue(value);
    onFilterChange(value.min, value.max);
  };

  return (
    <div className="price-filter-container">
      <InputRange
        minValue={minPrice}
        maxValue={maxPrice}
        value={rangeValue}
        onChange={handleRangeChange}
      />
      <span
        className="fw-bold"
        style={{ marginTop: "25px", display: "inline-block" }}
      >
        Price: ${rangeValue.min} - ${rangeValue.max}
      </span>
    </div>
  );
};

export default PriceFilter;
