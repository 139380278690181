import React from "react";

const LabelFilter = ({ handleLabelSelection, selectedLabel }) => {
  const labelClass = (label) => {
    switch (label) {
      case "NEW":
        return "form-check-warning";
      case "SALE":
        return "form-check-success";
      case "HOT":
        return "form-check-danger";
      default:
        return "";
    }
  };

  return (
    <div className=" widget py-2">
      <h5 className="widget_title">Label</h5>
      <div className="product_color_switch">
        <div className={`form-check form-check-inline ${labelClass("NEW")}`}>
          <input
            className="form-check-input"
            type="checkbox"
            id="inlineCheckbox1"
            value="NEW"
            onChange={() => handleLabelSelection("NEW")}
            checked={selectedLabel === "NEW"}
          />
          <label className="form-check-label" htmlFor="inlineCheckbox1">
            NEW
          </label>
        </div>
        <div className={`form-check form-check-inline ${labelClass("HOT")}`}>
          <input
            className="form-check-input"
            type="checkbox"
            id="inlineCheckbox2"
            value="HOT"
            onChange={() => handleLabelSelection("HOT")}
            checked={selectedLabel === "HOT"}
          />
          <label className="form-check-label" htmlFor="inlineCheckbox2">
            HOT
          </label>
        </div>
        <div className={`form-check form-check-inline ${labelClass("SALE")}`}>
          <input
            className="form-check-input"
            type="checkbox"
            id="inlineCheckbox3"
            value="SALE"
            onChange={() => handleLabelSelection("SALE")}
            checked={selectedLabel === "SALE"}
          />
          <label className="form-check-label" htmlFor="inlineCheckbox3">
            SALE
          </label>
        </div>
      </div>
    </div>
  );
};

export default LabelFilter;
