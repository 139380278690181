import React, { useState, useEffect } from "react";

const CategoriesFilter = ({
  categories,
  expandedCategories,
  handleCategoryClick,
  handleSubcategoryClick,
}) => {
  const [expandedSubcategories, setExpandedSubcategories] = useState([]);

  const toggleSubcategories = (categoryId) => {
    setExpandedSubcategories((prevExpandedSubcategories) => {
      if (prevExpandedSubcategories.includes(categoryId)) {
        return prevExpandedSubcategories.filter((id) => id !== categoryId);
      } else {
        return [...prevExpandedSubcategories, categoryId];
      }
    });
  };

  useEffect(() => {}, [expandedSubcategories]);

  return (
    <div className="widget">
      <h5 className="widget_title">Categories</h5>
      <ul className="widget_categories">
        {categories &&
          categories.map((category) => (
            <li key={category._id} className="main-category">
              <a
                href="#"
                onClick={() => {
                  handleCategoryClick(category._id);
                  toggleSubcategories(category._id);
                }}
              >
                <span className="categories_name">{category.name}</span>
                {category.subcategories &&
                  category.subcategories.length > 0 && (
                    <span className="categories_num">
                      ({category.subcategories.length})
                    </span>
                  )}
              </a>
              {expandedSubcategories.includes(category._id) && (
                <ul>
                  {category.subcategories &&
                    category.subcategories.map((subCategory) => (
                      <li key={subCategory._id}>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleSubcategoryClick(subCategory._id);
                          }}
                        >
                          {subCategory.name}
                        </a>
                      </li>
                    ))}
                </ul>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default CategoriesFilter;
