import { create } from "zustand";
import API from "../Services/API";

const parseJSON = (item, defaultValue) => {
  try {
    return JSON.parse(item) || defaultValue;
  } catch (error) {
    console.error("Error parsing JSON from localStorage:", error);
    return defaultValue;
  }
};

const initialCart = parseJSON(localStorage.getItem("cart"), []);
const initialWishlist = parseJSON(localStorage.getItem("wishlist"), []);
const initialCompare = parseJSON(localStorage.getItem("compare"), []);
const initialUser = parseJSON(localStorage.getItem("user"), null);
const isLoggedIn = !!initialUser;

const useGlobalStore = create((set) => ({
  cart: initialCart,
  wishlist: initialWishlist,
  compare: initialCompare,
  user: initialUser,
  isLoggedIn: isLoggedIn,

  addToCart: (product, quantity = 1) => {
    set((state) => {
      const updatedProduct = { ...product, quantity };
      const updatedCart = [...state.cart, updatedProduct];
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return { cart: updatedCart };
    });
  },

  increaseQuantity: (productId) => {
    set((state) => {
      const updatedCart = state.cart.map((item) =>
        item._id === productId ? { ...item, quantity: item.quantity + 1 } : item
      );
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return { cart: updatedCart };
    });
  },

  decreaseQuantity: (productId) => {
    set((state) => {
      const updatedCart = state.cart.map((item) =>
        item._id === productId && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      );
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return { cart: updatedCart };
    });
  },

  removeFromCart: (productId) => {
    set((state) => {
      const updatedCart = state.cart.filter((item) => item._id !== productId);
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return { cart: updatedCart };
    });
  },

  updateItemColor: (itemId, color) => {
    set((state) => {
      const updatedCart = state.cart.map((item) =>
        item._id === itemId ? { ...item, color } : item
      );
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return { cart: updatedCart };
    });
  },

  updateItemSize: (itemId, size) => {
    set((state) => {
      const updatedCart = state.cart.map((item) =>
        item._id === itemId ? { ...item, size } : item
      );
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return { cart: updatedCart };
    });
  },

  addToWishlist: (product) => {
    set((state) => {
      const updatedWishlist = [...state.wishlist, product];
      localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
      return { wishlist: updatedWishlist };
    });
  },

  removeFromWishlist: (productId) => {
    set((state) => {
      const updatedWishlist = state.wishlist.filter(
        (item) => item._id !== productId
      );
      localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
      return { wishlist: updatedWishlist };
    });
  },

  addToCompare: (product) => {
    set((state) => {
      const updatedCompare = [...state.compare, product];
      localStorage.setItem("compare", JSON.stringify(updatedCompare));
      return { compare: updatedCompare };
    });
  },

  removeFromCompare: (productId) => {
    set((state) => {
      const updatedCompare = state.compare.filter(
        (item) => item._id !== productId
      );
      localStorage.setItem("compare", JSON.stringify(updatedCompare));
      return { compare: updatedCompare };
    });
  },

  login: async (userData) => {
    try {
      const response = await API.post("/auth/login", userData);
      const { data } = response;
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.user));
      set({ user: data.user, isLoggedIn: true });
      return data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },

  logout: async () => {
    try {
      await API.post("/auth/logout");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      useGlobalStore
        .getState()
        .socket.emit("sendNotification", "A User has Logged Out!");
      set(() => ({
        cart: [],
        wishlist: [],
        compare: [],
        user: null,
        isLoggedIn: false,
      }));
    } catch (error) {
      console.error("Error logging out:", error);
      throw error;
    }
  },

  getCart: (state) => state.cart,
  getWishlist: (state) => state.wishlist,
  getCompare: (state) => state.compare,
  getUser: (state) => state.user,
}));

export default useGlobalStore;
