import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../Hooks/UseFetch";
import API from "../../Services/API";
import toast from "react-hot-toast";
import useGlobalStore from "../../Store/Store";
import Related_Products from "../../Components/Related_Products/Related_Products";
import Newsletter from "../../Components/Newsletter/Newsletter";
import Footer from "../../Components/Footer/Footer";
import Navigation from "../../Components/Navigation/Navigation";
import ReactImageMagnify from "react-image-magnify";

const ProductDetails = (productData) => {
  const { productId } = useParams();
  // const { data, error } = useFetch("/products");
  const [categoryName, setCategoryName] = useState("");
  const [selectedSize, setSelectedSize] = useState(null);
  const [activeTab, setActiveTab] = useState("Description");
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const { wishlist, addToWishlist, cart, addToCart, compare, addToCompare } =
    useGlobalStore();
  const [product, setProduct] = useState({
    regular_price: 0,
    sale_price: 0,
    gallery: [],
    colors: [],
    sizes: [],
  });

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    // Update smallImage and largeImage src properties
    const selectedImage = product.gallery[index];
    setProduct({
      ...product,
      photo: selectedImage,
    });
  };

  useEffect(() => {
    setSelectedImageIndex(0);
  }, [product]);

  useEffect(() => {
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
    localStorage.setItem("compare", JSON.stringify(compare));
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [wishlist, compare, cart]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await API.get(`/products/${productId}`);
        setProduct(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProduct();

    const fetchCategoryName = async () => {
      try {
        const response = await API.get(`/categories/${product.category}`);
        console.log(response.data.name);
        setCategoryName(response.data.name);
      } catch (error) {
        console.error("Error fetching category name:", error);
      }
    };

    if (product.category) {
      fetchCategoryName();
    }
  }, [productId, product.category]);

  useEffect(() => {
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
  }, [wishlist]);

  useEffect(() => {
    localStorage.setItem("compare", JSON.stringify(compare));
  }, [compare]);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const handleColorSelection = (color) => {
    setSelectedColor(color);
  };

  const handleSizeSelection = (size) => {
    setSelectedSize(size);
  };

  const regularPrice = parseFloat(product.regular_price);
  const salePrice = parseFloat(product.sale_price);
  const discountPercentage = ((regularPrice - salePrice) / regularPrice) * 100;

  const handleAddToCompare = (event, product) => {
    event.preventDefault();
    if (!isInCompare(product._id)) {
      addToCompare(product);
      toast.success("Product added to Compare");
    } else {
      toast.error("Product is already in Compare");
    }
  };

  const handleAddToWishlist = (event, product) => {
    event.preventDefault();
    if (!isInWishlist(product._id)) {
      addToWishlist(product);
      toast.success("Product added to Wishlist");
    } else {
      toast.error("Product is already in Wishlist");
    }
  };

  const isInCompare = useMemo(() => {
    return (productId) => compare.some((item) => item._id === productId);
  }, [compare]);

  const isInCart = useMemo(() => {
    return (productId) => cart.some((item) => item._id === productId);
  }, [cart]);

  const isInWishlist = useMemo(() => {
    return (productId) => wishlist.some((item) => item._id === productId);
  }, [wishlist]);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const openModal = (event, product) => {
    event.preventDefault();
    setSelectedProduct(product);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const AddToCartClick = () => {
    const isProductInCart = cart.some((item) => item._id === product._id);

    if (isProductInCart) {
      toast.error("Product is already in Cart");
      return;
    }

    const updatedProduct = {
      ...product,
      color: selectedColor,
      size: selectedSize,
    };

    addToCart(updatedProduct, selectedQuantity);
    toast.success("Product added to Cart");
  };

  const increaseQuantity = () => {
    setSelectedQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    setSelectedQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
  };

  const zoomedPhoto = String(product.photo || "");

  return (
    <div>
      <Navigation />
      <div>
        <div className="container">
          <div className="row my-5">
            <div className="col-lg-6 col-md-6 mb-4 mb-md-0">
              <div className="product-image">
                <div className="product_img_box">
                  <ReactImageMagnify
                    smallImage={{
                      alt: zoomedPhoto ? "Product Photo" : "",
                      isFluidWidth: true,
                      src: zoomedPhoto || "",
                      width: 400,
                      height: 600,
                    }}
                    largeImage={{
                      src: zoomedPhoto || "",
                      width: 1000,
                      height: 1600,
                    }}
                    enlargedImagePosition="over"
                    isHintEnabled={true}
                  />
                </div>
              </div>
              <div className="image-gallery">
                {product.gallery.map((image, index) => (
                  <img
                    id={`product_img_${index}`}
                    src={image}
                    alt="Product Image"
                    className="gallery-item"
                    key={image}
                    onClick={() => handleImageClick(index)}
                  />
                ))}
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="pr_detail">
                <div className="product_description">
                  <h4 className="product_title">
                    <a href="#">{product.title}</a>
                  </h4>
                  <div className="d-flex align-items-center justify-content-between py-2">
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <span
                        className="price"
                        style={{
                          fontSize: "22px",
                          fontWeight: 600,
                          color: "red",
                        }}
                      >
                        $
                        {product.sale_price
                          ? product.sale_price
                          : product.regular_price}
                        .00
                      </span>
                      {product.sale_price && (
                        <>
                          <del>${product.regular_price}.00</del>
                          <div className="on_sale">
                            <span>
                              {`${Math.round(
                                ((product.regular_price - product.sale_price) /
                                  product.regular_price) *
                                  100
                              )}% Off`}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="rating_wrap">
                      <div className="rating">
                        <div
                          className="product_rate"
                          style={{ width: "80%" }}
                        ></div>
                      </div>
                      <span className="rating_num">(21)</span>
                    </div>
                  </div>
                  {/* Render dynamic HTML content */}
                  <div
                    className="pr_desc"
                    dangerouslySetInnerHTML={{
                      __html: product.description,
                    }}
                  />
                  <div className="product_sort_info">
                    <ul style={{ paddingLeft: "0" }}>
                      <li>
                        <i className="linearicons-shield-check"></i> 1 Year
                        Brand Warranty
                      </li>
                      <li>
                        <i className="linearicons-sync"></i> 30 Day Return
                        Policy
                      </li>
                      <li>
                        <i className="linearicons-bag-dollar"></i> Cash on
                        Delivery available
                      </li>
                    </ul>
                  </div>
                  <hr />
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pr_switch_wrap">
                      <div className="product_color_switch">
                        Color
                        <div style={{ marginLeft: "-5px" }}>
                          {product.colors.map((color, index) => (
                            <span
                              key={index}
                              className={
                                selectedColor === color ? "active" : ""
                              }
                              data-color={color}
                              onClick={() => handleColorSelection(color)}
                              style={{ backgroundColor: color }}
                            ></span>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* Vertical line */}
                    <div className="vertical-line"></div>
                    <div className="pr_switch_wrap">
                      Size
                      <div className="sizes-box">
                        <div className="d-flex">
                          {product.sizes.map((size, index) => (
                            <span
                              key={index}
                              className={`size-swatch ${
                                selectedSize === size ? "active" : ""
                              }`}
                              onClick={() => handleSizeSelection(size)}
                            >
                              {size}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="cart_extra">
                  <div className="cart-product-quantity">
                    <div className="quantity">
                      <input
                        type="button"
                        value="-"
                        className="minus"
                        onClick={decreaseQuantity}
                      />
                      <input
                        type="text"
                        name="quantity"
                        value={selectedQuantity}
                        title="Qty"
                        className="qty"
                        size="4"
                        onChange={(e) =>
                          setSelectedQuantity(parseInt(e.target.value))
                        }
                      />
                      <input
                        type="button"
                        value="+"
                        className="plus"
                        onClick={increaseQuantity}
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={AddToCartClick}
                    >
                      <i className="icon-basket-loaded"></i> Add to cart
                    </button>
                  </div>
                  <div className="cart_btn" style={{ marginRight: "10px" }}>
                    <a
                      className="add_compare"
                      href="#"
                      onClick={(event) => handleAddToCompare(event, product)}
                    >
                      <i className="icon-shuffle"></i>
                    </a>
                    <a
                      className="add_wishlist"
                      href="#"
                      onClick={(event) => handleAddToWishlist(event, product)}
                    >
                      <i className="icon-heart"></i>
                    </a>
                  </div>
                </div>
                <hr />
                <ul className="product-meta">
                  <li>
                    SKU: <a href="#">{product.sku}</a>
                  </li>
                  <li>
                    Category: <a href="#">{categoryName}</a>
                  </li>
                  <li>
                    Tag:{" "}
                    <a href="#" rel="tag">
                      {product.tag}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tab-style3">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "Description" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("Description")}
                    >
                      Description
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      id="Additional-info-tab"
                      className={`nav-link ${
                        activeTab === "Additional-info" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("Additional-info")}
                    >
                      Additional info
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "Reviews" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("Reviews")}
                    >
                      Reviews (2)
                    </a>
                  </li>
                </ul>
                <div className="tab-content shop_info_tab">
                  <div
                    className={`tab-pane fade ${
                      activeTab === "Description" ? "active show" : ""
                    }`}
                    id="Description"
                  >
                    <p>
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text. It has roots in a piece of classical Latin
                      literature from 45 BC, making it over 2000 years old.
                      Vivamus bibendum magna Lorem ipsum dolor sit amet,
                      consectetur adipiscing elit.Contrary to popular belief,
                      Lorem Ipsum is not simply random text. It has roots in a
                      piece of classical Latin literature from 45 BC, making it
                      over 2000 years old.
                    </p>
                    <p>
                      At vero eos et accusamus et iusto odio dignissimos ducimus
                      qui blanditiis praesentium voluptatum deleniti atque
                      corrupti quos dolores et quas molestias excepturi sint
                      occaecati cupiditate non provident, similique sunt in
                      culpa qui officia deserunt mollitia animi, id est laborum
                      et dolorum fuga. Et harum quidem rerum facilis est et
                      expedita distinctio.
                    </p>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      activeTab === "Additional-info" ? "active show" : ""
                    }`}
                    id="Additional-info"
                  >
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td>Capacity</td>
                          <td>5 Kg</td>
                        </tr>
                        <tr>
                          <td>Color</td>
                          <td>Black, Brown, Red,</td>
                        </tr>
                        <tr>
                          <td>Water Resistant</td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>Material</td>
                          <td>Artificial Leather</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      activeTab === "Reviews" ? "active show" : ""
                    }`}
                    id="Reviews"
                  >
                    <div className="comments">
                      <h5 className="product_tab_title">
                        2 Review For <span>Blue Dress For Woman</span>
                      </h5>
                      <ul className="list_none comment_list mt-4">
                        <li>
                          <div className="comment_img">
                            <img
                              src="assets/images/user1.jpg"
                              alt="user1"
                              width={100}
                            />
                          </div>
                          <div className="comment_block">
                            <div className="rating_wrap">
                              <div className="rating">
                                <div
                                  className="product_rate"
                                  style={{ width: "80%" }}
                                ></div>
                              </div>
                            </div>
                            <p className="customer_meta">
                              <span className="review_author">Alea Brooks</span>
                              <span className="comment-date">
                                March 5, 2018
                              </span>
                            </p>
                            <div className="description">
                              <p>
                                Lorem Ipsumin gravida nibh vel velit auctor
                                aliquet. Aenean sollicitudin, lorem quis
                                bibendum auctor, nisi elit consequat ipsum, nec
                                sagittis sem nibh id elit. Duis sed odio sit
                                amet nibh vulputate
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="comment_img">
                            <img src="assets/images/user2.jpg" alt="user2" />
                          </div>
                          <div className="comment_block">
                            <div className="rating_wrap">
                              <div className="rating">
                                <div
                                  className="product_rate"
                                  style={{ width: "60%" }}
                                ></div>
                              </div>
                            </div>
                            <p className="customer_meta">
                              <span className="review_author">Grace Wong</span>
                              <span className="comment-date">
                                June 17, 2018
                              </span>
                            </p>
                            <div className="description">
                              <p>
                                It is a long established fact that a reader will
                                be distracted by the readable content of a page
                                when looking at its layout. The point of using
                                Lorem Ipsum is that it has a more-or-less normal
                                distribution of letters
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <div className="review_form field_form">
                        <h5>Add a review</h5>
                        <form className="row mt-3">
                          <div className="input-group col-12">
                            <div className="star_rating">
                              <span data-value="1">
                                <i className="far fa-star"></i>
                              </span>
                              <span data-value="2">
                                <i className="far fa-star"></i>
                              </span>
                              <span data-value="3">
                                <i className="far fa-star"></i>
                              </span>
                              <span data-value="4">
                                <i className="far fa-star"></i>
                              </span>
                              <span data-value="5">
                                <i className="far fa-star"></i>
                              </span>
                            </div>
                          </div>
                          <div className="col-12 my-3">
                            <textarea
                              required="required"
                              placeholder="Your review *"
                              className="form-control"
                              name="message"
                              rows="4"
                            ></textarea>
                          </div>
                          <div className="row">
                            <div className="col-md-6 my-2">
                              <input
                                required="required"
                                placeholder="Enter Name *"
                                className="form-control"
                                name="name"
                                type="text"
                              />
                            </div>
                            <div className="col-md-6 my-2">
                              <input
                                required="required"
                                placeholder="Enter Email *"
                                className="form-control"
                                name="email"
                                type="email"
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <button
                              type="submit"
                              className="btn btn-primary btn-md"
                              name="submit"
                              value="Submit"
                            >
                              Submit Review
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
          <Related_Products productData={productData} />
        </div>
        <Newsletter />
        <Footer />
      </div>
    </div>
  );
};

export default ProductDetails;
