import React, { useState, useEffect } from "react";
import "./App.css";
import { Toaster, toast } from "react-hot-toast";
import { Routes, Route } from "react-router-dom";
import Homepage from "./Pages/Homepage/Homepage";
import Wishlist from "./Pages/Wishlist/Wishlist";
import Compare from "./Pages/Compare/Compare";
import Cart from "./Pages/Cart/Cart";
import Checkout from "./Pages/Checkout/Checkout";
import ShopPage from "./Pages/ShopPage/ShopPage";
import ProductDetails from "./Pages/ProductDetails/ProductDetails";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Success from "./Pages/PaymentSuccess/Success";
import Contact from "./Pages/Contact/Contact";
import About from "./Pages/About/About";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Register";
import OrderTracking from "./Pages/OrderTracking/OrderTracking";
import API from "./Services/API";
import Spinner from "./Components/shared/Spinner";

function App() {
  const [productData, setProductData] = useState([]);
  const [sliderData, setSliderData] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [aboutData, setAboutData] = useState([]);
  const [service, setService] = useState([]);
  const [loading, setLoading] = useState({
    essential: true,
    additional: false,
  });
  const [error, setError] = useState(null);

  // Fetch essential data (products and sliders)
  useEffect(() => {
    const fetchEssentialData = async () => {
      setLoading((prevState) => ({ ...prevState, essential: true }));
      setError(null);
      try {
        const [productsResponse, slidersResponse, serviceResponse] =
          await Promise.all([
            API.get("/products"),
            API.get("/sliders"),
            API.get("/services"),
          ]);

        const productsData = Array.isArray(productsResponse.data.products)
          ? productsResponse.data.products
          : [];
        console.log(productData);
        setProductData(productsData);
        setSliderData(slidersResponse.data);
        setService(serviceResponse.data);
      } catch (err) {
        setError(err);
        toast.error("Error fetching essential data");
      } finally {
        setLoading((prevState) => ({ ...prevState, essential: false }));
      }
    };

    fetchEssentialData();
  }, []);

  // Fetch additional data (categories, banners, about) after essential data
  useEffect(() => {
    if (!loading.essential && !error) {
      const fetchAdditionalData = async () => {
        setLoading((prevState) => ({ ...prevState, additional: true }));
        try {
          const [categoriesResponse, bannersResponse, aboutResponse] =
            await Promise.all([
              API.get("/categories"),
              API.get("/banners/66fe5e699dfc51bd21cdd529"),
              API.get("/abouts/66fe5e2e9dfc51bd21cdd51c"),
            ]);
          const topLevelCategories = categoriesResponse.data.filter(
            (category) => !category.parent
          );
          setCategories(topLevelCategories);
          setBannerData(bannersResponse.data);
          setAboutData(aboutResponse.data);
        } catch (err) {
          setError(err);
          toast.error("Error fetching additional data");
        } finally {
          setLoading((prevState) => ({ ...prevState, additional: false }));
        }
      };

      fetchAdditionalData();
    }
  }, [loading.essential, error]);

  if (loading.essential) return <Spinner />;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <Toaster />
      <Routes>
        <Route
          path="/"
          element={
            <Homepage
              productData={productData}
              sliderData={sliderData}
              service={service}
            />
          }
        />
        <Route
          path="/shoppage"
          element={
            <ShopPage
              productData={productData}
              bannerData={bannerData}
              categories={categories}
            />
          }
        />
        <Route
          path="/product-details/:productId"
          element={<ProductDetails productData={productData} />}
        />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/compare" element={<Compare />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/success" element={<Success />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About about={aboutData} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/order-tracking" element={<OrderTracking />} />
      </Routes>
    </div>
  );
}

export default App;
