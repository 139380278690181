import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import API from "../../Services/API";

const Footer = () => {
  const [settings, setSettings] = useState({
    address: "",
    email: "",
    phone: "",
    facebookLink: "",
    twitterLink: "",
    youtubeLink: "",
    pinterestLink: "",
    linkedInLink: "",
  });

  useEffect(() => {
    API.get("/userSettings")
      .then((response) => {
        if (response.data) {
          // Update state with fetched settings
          setSettings(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching settings:", error);
      });
  }, []);

  return (
    <>
      <footer className="footer_dark">
        <div className="footer_top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="widget">
                  <div className="footer_logo">
                    <a href="#">
                      <h4>BRAND NAME</h4>
                    </a>
                  </div>
                  <p>
                    If you are going to use of Lorem Ipsum need to be sure there
                    isn't hidden of text
                  </p>
                </div>
                <div className="widget">
                  <ul className="social_icons social_white">
                    <li>
                      <a href={settings.facebookLink}>
                        <i className="ion-social-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={settings.twitterLink}>
                        <i className="ion-social-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href={settings.pinterestLink}>
                        <i className="ion-social-pinterest"></i>
                      </a>
                    </li>
                    <li>
                      <a href={settings.youtubeLink}>
                        <i className="ion-social-youtube-outline"></i>
                      </a>
                    </li>
                    <li>
                      <a href={settings.linkedInLink}>
                        <i className="ion-social-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6">
                <div className="widget">
                  <h6 className="widget_title">Useful Links</h6>
                  <ul className="widget_links">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/shoppage">Shop</Link>
                    </li>
                    <li>
                      <Link to="/checkout">Checkout</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6">
                <div className="widget">
                  <h6 className="widget_title">Feature</h6>
                  <ul className="widget_links">
                    <li>
                      <Link to="/compare">Compare</Link>
                    </li>
                    <li>
                      <Link to="/wishlist">Wishlist</Link>
                    </li>
                    <li>
                      <Link to="/cart">Cart</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6">
                <div className="widget">
                  <h6 className="widget_title">My Account</h6>
                  <ul className="widget_links">
                    <li>
                      <Link to="/login">My Account</Link>
                    </li>
                    <li>
                      <Link to="/order-tracking">Order Tracking</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="widget">
                  <h6 className="widget_title">Contact Info</h6>
                  <ul className="contact_info contact_info_light">
                    <li>
                      <i className="ti-location-pin"></i>
                      <span>{settings.address}</span>
                    </li>
                    <li>
                      <i className="ti-email"></i>
                      <a href="#">{settings.email}</a>
                    </li>
                    <li>
                      <i className="ti-mobile"></i>
                      <span>{settings.phone}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
