import LeftBanner from "./LeftBanner";

const First_Banner = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <LeftBanner />
          <div className="col-md-6 col-lg-6">
            <div className="single_banner">
              <img
                src="assets/images/shop_banner_img13.png"
                alt="shop_banner_img2"
              />
              <div className="single_banner_info">
                <h3 className="single_bn_title">New Season</h3>
                <h4 className="single_bn_title1">Sale 40% Off</h4>
                <a href="shop-left-sidebar.html" className="single_bn_link">
                  Shop Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default First_Banner;
