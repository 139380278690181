import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useGlobalStore from "../../Store/Store";
import Navigation from "../../Components/Navigation/Navigation";
import Newsletter from "../../Components/Newsletter/Newsletter";
import Footer from "../../Components/Footer/Footer";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, socket } = useGlobalStore();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Clear previous errors

    try {
      const userData = { email, password };
      const data = await login(userData);
      if (data) {
        socket.emit("sendNotification", "A User Logged in Successfully!");
        navigate("/"); // Redirect to profile or another page after successful login
      }
    } catch (error) {
      setError("Invalid email or password");
      console.error("Error logging in:", error);
    }
  };

  return (
    <div>
      <Navigation />
      <div>
        <div className="login_register_wrap section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-md-10">
                <div className="login_wrap">
                  <div className="padding_eight_all bg-white">
                    <div className="heading_s1">
                      <h3 className="text-center">Login</h3>
                    </div>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          type="text"
                          required=""
                          className="form-control"
                          name="email"
                          placeholder="Your Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          required=""
                          type="password"
                          name="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="login_footer form-group">
                        <div className="chek-form">
                          <div className="custome-checkbox">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="checkbox"
                              id="exampleCheckbox1"
                              value=""
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheckbox1"
                            >
                              <span style={{ marginLeft: "10px" }}>
                                Remember me
                              </span>
                            </label>
                          </div>
                        </div>
                        <a href="#">Forgot password?</a>
                      </div>
                      <div className="d-grid d-block">
                        <button
                          type="submit"
                          className="btn btn-danger "
                          name="login"
                          style={{ marginBottom: "30px" }}
                        >
                          Login
                        </button>
                      </div>
                    </form>
                    <div className="form-note text-center">
                      Don't have an account?{" "}
                      <Link to="/register">Register</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Login;
