import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactImageMagnify from "react-image-magnify";
import useGlobalStore from "../../Store/Store";
import { toast } from "react-hot-toast";
import Spinner from "../shared/Spinner";
import "./product_modal.scss";
import API from "../../Services/API";

const ProductModal = ({ product, closeModal }) => {
  const [zoomedPhoto, setZoomedPhoto] = useState("");
  const [selectedImage, setSelectedImage] = useState(product.photo || "");
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [loading, setLoading] = useState(true);
  const { cart, addToCart, wishlist, addToWishlist, compare, addToCompare } =
    useGlobalStore();
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const regularPrice = parseFloat(product.regular_price);
  const salePrice = parseFloat(product.sale_price);
  const hasSalePrice = !isNaN(salePrice);

  useEffect(() => {
    if (product.photo) {
      setLoading(false);
    }
  }, [product.photo]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  useEffect(() => {
    if (product.gallery && product.gallery.length > 0) {
      setZoomedPhoto(product.gallery[selectedImage]);
    }
  }, [selectedImage, product.gallery]);

  let discountPercentage = 0;

  if (hasSalePrice) {
    discountPercentage = ((regularPrice - salePrice) / regularPrice) * 100;
  }

  const handleColorSelection = (color) => {
    setSelectedColor(color);
  };

  const handleSizeSelection = (size) => {
    setSelectedSize(size);
  };

  const handleAddToWishlist = (event, product) => {
    event.preventDefault();
    if (!isInWishlist(product._id)) {
      addToWishlist(product);
      toast.success("Product added to Wishlist");
    } else {
      toast.error("Product is already in Wishlist");
    }
  };

  const handleAddToCompare = (event, product) => {
    event.preventDefault();
    if (!isInCompare(product._id)) {
      addToCompare(product);
      toast.success("Product added to Compare");
    } else {
      toast.error("Product is already in Compare");
    }
  };

  const isInWishlist = (productId) =>
    wishlist.some((item) => item._id === productId);

  const isInCompare = (productId) =>
    compare.some((item) => item._id === productId);

  useEffect(() => {
    const fetchCategoryName = async () => {
      try {
        const response = await API.get(`/categories/${product.category}`);
        setCategoryName(response.data.name);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching category name:", error);
      }
    };

    fetchCategoryName();
  }, [product.category]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".modal-container")) {
        closeModal();
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [closeModal]);

  const AddToCartClick = () => {
    const isProductInCart = cart.some((item) => item._id === product._id);

    if (isProductInCart) {
      toast.error("Product is already in Cart");
      return;
    }

    const updatedProduct = {
      ...product,
      color: selectedColor,
      size: selectedSize,
    };

    addToCart(updatedProduct, selectedQuantity);
    closeModal();
    toast.success("Product added to Cart");
  };

  const increaseQuantity = () => {
    setSelectedQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    setSelectedQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
  };

  return (
    <div className="modal-overlay">
      {loading ? (
        <Spinner />
      ) : (
        <div className="row modal-container">
          <span className="close-modal" onClick={closeModal}>
            <i className="ti-close"></i>
          </span>
          <div className="col-lg-6 col-md-6 mb-4 mb-md-0">
            <div className="product-image">
              <div className="product_img_box">
                <ReactImageMagnify
                  smallImage={{
                    alt: selectedImage ? "Product Photo" : "",
                    isFluidWidth: true,
                    src: selectedImage || "",
                    width: 400,
                    height: 600,
                  }}
                  largeImage={{
                    src: selectedImage || "",
                    width: 1000,
                    height: 1600,
                  }}
                  enlargedImagePosition="over"
                  isHintEnabled={true}
                />
              </div>
            </div>
            <div className="image-gallery2">
              {product.gallery.map((image, index) => (
                <img
                  id={`product_img_${index}`}
                  src={image}
                  alt="Product Image"
                  className="gallery-item"
                  key={index}
                  onClick={() => handleImageClick(image)}
                />
              ))}
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="pr_detail">
              <div className="product_description">
                <h4 className="product_title">
                  <a href="#">{product.title}</a>
                </h4>
                <div className="d-flex align-items-center justify-content-between py-2">
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ gap: "10px" }}
                  >
                    {hasSalePrice && (
                      <>
                        <span
                          className="fw-bold text-danger"
                          style={{ fontSize: "18px" }}
                        >
                          ${product.sale_price}.00
                        </span>
                        <del>${product.regular_price}.00</del>
                      </>
                    )}
                    {!hasSalePrice && (
                      <span className="fw-bold text-danger">
                        ${product.regular_price}.00
                      </span>
                    )}
                    {hasSalePrice && (
                      <div className="on_sale">
                        <span>{`${Math.round(discountPercentage)}% Off`}</span>
                      </div>
                    )}
                  </div>
                  <div className="rating_wrap">
                    <div className="rating">
                      <div
                        className="product_rate"
                        style={{ width: "80%" }}
                      ></div>
                    </div>
                    <span className="rating_num">(21)</span>
                  </div>
                </div>
                {/* Render dynamic HTML content */}
                <div
                  className="pr_desc"
                  dangerouslySetInnerHTML={{ __html: product.description }}
                />
                <div className="product_sort_info">
                  <ul style={{ paddingLeft: "0" }}>
                    <li>
                      <i className="linearicons-shield-check"></i> 1 Year Brand
                      Warranty
                    </li>
                    <li>
                      <i className="linearicons-sync"></i> 30 Day Return Policy
                    </li>
                    <li>
                      <i className="linearicons-bag-dollar"></i> Cash on
                      Delivery available
                    </li>
                  </ul>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <div className="pr_switch_wrap">
                    <div className="product_color_switch">
                      Color
                      <div style={{ marginLeft: "-5px" }}>
                        {product.colors.map((color, index) => (
                          <span
                            key={index}
                            className={selectedColor === color ? "active" : ""}
                            data-color={color}
                            onClick={() => handleColorSelection(color)}
                            style={{ backgroundColor: color }}
                          ></span>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* Vertical line */}
                  <div className="vertical-line"></div>
                  <div className="pr_switch_wrap">
                    Size
                    <div className="sizes-box">
                      <div className="d-flex">
                        {product.sizes.map((size, index) => (
                          <span
                            key={index}
                            className={`size-swatch ${
                              selectedSize === size ? "active" : ""
                            }`}
                            onClick={() => handleSizeSelection(size)}
                          >
                            {size}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="cart_extra">
                <div className="cart-product-quantity">
                  <div className="quantity">
                    <input
                      type="button"
                      value="-"
                      className="minus"
                      onClick={decreaseQuantity}
                    />
                    <input
                      type="text"
                      name="quantity"
                      value={selectedQuantity}
                      title="Qty"
                      className="qty"
                      size="4"
                      onChange={(e) =>
                        setSelectedQuantity(parseInt(e.target.value))
                      }
                    />
                    <input
                      type="button"
                      value="+"
                      className="plus"
                      onClick={increaseQuantity}
                    />
                  </div>
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={AddToCartClick}
                  >
                    <i className="icon-basket-loaded"></i> Add to cart
                  </button>
                </div>
                <div className="cart_btn" style={{ marginRight: "10px" }}>
                  <a
                    className="add_compare"
                    href="#"
                    onClick={(event) => handleAddToCompare(event, product)}
                  >
                    <i className="icon-shuffle"></i>
                  </a>
                  <a
                    className="add_wishlist"
                    href="#"
                    onClick={(event) => handleAddToWishlist(event, product)}
                  >
                    <i className="icon-heart"></i>
                  </a>
                </div>
              </div>
              <hr />
              <ul className="product-meta">
                <li>
                  SKU: <a href="#">{product.sku}</a>
                </li>
                <li>
                  Category: <a href="#">{categoryName}</a>
                </li>
                <li>
                  Tag:{" "}
                  <a href="#" rel="tag">
                    {product.tag}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductModal;
