import React, { useState } from "react";
import BillingDetailsForm from "../../Components/BillingDetails/BillingDetailsForm";
import useGlobalStore from "../../Store/Store";
import { loadStripe } from "@stripe/stripe-js";
import Navigation from "../../Components/Navigation/Navigation";
import Newsletter from "../../Components/Newsletter/Newsletter";
import Footer from "../../Components/Footer/Footer";
import API from "../../Services/API";
import { useNavigate } from "react-router-dom";

const Stripe_Key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(Stripe_Key);

const Checkout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const Navigate = useNavigate();
  const [error, setError] = useState("");
  const [billingInfo, setBillingInfo] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    country: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    phone: "",
    email: "",
    orderNotes: "",
  });

  const [shippingInfo, setShippingInfo] = useState({
    shippingFirstName: "",
    shippingLastName: "",
    shippingCompanyName: "",
    shippingCountry: "",
    shippingAddress: "",
    shippingAddress2: "",
    shippingCity: "",
    shippingState: "",
    shippingZipcode: "",
    shippingPhone: "",
    shippingEmail: "",
  });

  const { cart } = useGlobalStore();
  const [showShippingAddress, setShowShippingAddress] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    // Example validation for required fields
    if (!billingInfo.firstName.trim()) {
      errors.firstName = "First name is required";
    }

    if (!billingInfo.lastName.trim()) {
      errors.lastName = "Last name is required";
    }

    if (!billingInfo.country.trim()) {
      errors.country = "Country is required";
    }

    if (!billingInfo.address.trim()) {
      errors.address = "Address is required";
    }

    if (!billingInfo.city.trim()) {
      errors.city = "City is required";
    }

    if (!billingInfo.state.trim()) {
      errors.state = "State is required";
    }

    if (!billingInfo.zipcode.trim()) {
      errors.zipcode = "Zipcode is required";
    }

    if (!billingInfo.phone.trim()) {
      errors.phone = "Phone is required";
    }

    if (!billingInfo.email.trim()) {
      errors.email = "Email is required";
    }

    // Return errors object
    return errors;
  };

  const subtotal = cart.reduce(
    (totalPrice, item) =>
      totalPrice + (item.sale_price || item.regular_price || 0) * item.quantity,
    0
  );

  const total = subtotal;

  const handleCheckout = async () => {
    try {
      setIsLoading(true);
      // Validate form fields
      const formErrors = validateForm();
      setErrors(formErrors);

      // Check if there are any validation errors
      if (Object.keys(formErrors).length > 0) {
        setError("Please fill in all required fields");
        setIsLoading(false);
        return;
      }

      if (!selectedPaymentOption) {
        setError("Please select a payment option");
        return;
      }

      // Calculate total amount
      const totalAmount = cart.reduce(
        (total, item) =>
          total + (item.sale_price || item.regular_price || 0) * item.quantity,
        0
      );

      // Proceed with checkout process
      const combinedData = {
        billingInfo: billingInfo,
        shippingInfo: showShippingAddress ? shippingInfo : null,
        items: cart.map((item) => ({
          productPhoto: item.photo[0],
          productId: item._id,
          title: item.title,
          color: item.color,
          size: item.size,
          quantity: item.quantity,
          price: item.sale_price || item.regular_price, // Use sale price if available, otherwise use regular price
        })),
        totalPrice: total,
        paymentMethod: selectedPaymentOption === "stripe" ? "CARD" : "COD",
      };

      // Handle Stripe payment
      if (selectedPaymentOption === "stripe") {
        const stripe = await stripePromise;
        const response = await API.post("/payments/stripe", {
          products: cart,
        });

        const response2 = await API.post("/orders", combinedData);

        const session = response.data;
        if (!session || !session.id) {
          throw new Error("Invalid checkout session data");
        }
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });
      }

      // Handle Cash on Delivery
      if (selectedPaymentOption === "cashOnDelivery") {
        const response = await API.post("/orders", combinedData);
        Navigate("/success");
      }
    } catch (error) {
      console.error("Error during checkout:", error);
      setError("Failed to complete checkout");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handleBillingInputChange = (e) => {
    const { name, value } = e.target;
    setBillingInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleShippingInputChange = (e) => {
    const { name, value } = e.target;
    setShippingInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleShowShippingAddressChange = (e) => {
    const { checked } = e.target;
    setShowShippingAddress(checked);

    // If shipping address is being shown, update shippingInfo with a copy of billingInfo
    if (checked) {
      setShippingInfo({ ...billingInfo });
    } else {
      // If shipping address is not being shown, reset shippingInfo
      setShippingInfo({
        shippingFirstName: "",
        shippingLastName: "",
        shippingCompanyName: "",
        shippingCountry: "",
        shippingAddress: "",
        shippingAddress2: "",
        shippingCity: "",
        shippingState: "",
        shippingZipcode: "",
        shippingPhone: "",
        shippingEmail: "",
      });
    }
  };

  return (
    <div>
      <Navigation />
      <div className="section">
        <div className="container">
          <div className="row">
            <BillingDetailsForm
              billingInfo={billingInfo}
              setShippingInfo={setShippingInfo}
              shippingInfo={shippingInfo}
              handleBillingInputChange={handleBillingInputChange}
              handleShippingInputChange={handleShippingInputChange}
              handleShowShippingAddressChange={handleShowShippingAddressChange}
              showShippingAddress={showShippingAddress}
              setShowShippingAddress={setShowShippingAddress}
              errors={errors}
            />
            <div className="col-md-6">
              <div className="order_review">
                <div className="heading_s1">
                  <h4>Your Orders</h4>
                </div>
                <div className="table-responsive order_table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.map((item) => (
                        <tr key={item._id}>
                          <td>
                            <div>
                              <div className="product">
                                {item.title}
                                <span className="product-qty">
                                  {" "}
                                  x {item.quantity}
                                </span>
                              </div>
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "30px" }}
                              >
                                <div className="product_color_switch2">
                                  Color:
                                  <span
                                    className="color-swatch"
                                    data-color={item.color}
                                    style={{
                                      backgroundColor: item.color,
                                      marginLeft: "10px",
                                    }}
                                  ></span>
                                </div>
                                <div className="sizes-box">
                                  Size:
                                  <span
                                    className="size-swatch"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    {item.size}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td style={{ verticalAlign: "middle" }}>
                            $
                            {(item.sale_price || item.regular_price) *
                              item.quantity}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>SubTotal</th>
                        <td className="product-subtotal">
                          ${subtotal.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <th>Shipping</th>
                        <td>Free Shipping</td>
                      </tr>
                      <tr>
                        <th>Total</th>
                        <td className="product-subtotal">
                          ${total.toFixed(2)}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className="payment_method">
                  <div className="heading_s1">
                    <h4>Payment</h4>
                  </div>
                  <div className="payment_option">
                    <div className="custome-radio">
                      <input
                        className="form-check-input"
                        required=""
                        type="radio"
                        name="payment_option"
                        id="cashOnDeliveryOption"
                        value="cashOnDelivery"
                        checked={selectedPaymentOption === "cashOnDelivery"}
                        onChange={() =>
                          setSelectedPaymentOption("cashOnDelivery")
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios3"
                        style={{ marginLeft: "10px" }}
                      >
                        Cash on Delivery
                      </label>
                      <div data-method="option3" className="payment-text">
                        <div style={{ marginLeft: "10px" }}>
                          Payment will be made after successful delivery within
                          3 days
                        </div>
                      </div>
                    </div>
                    <div className="custome-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="payment_option"
                        id="stripeOption"
                        value="stripe"
                        checked={selectedPaymentOption === "stripe"}
                        onChange={() => setSelectedPaymentOption("stripe")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios5"
                        style={{ marginLeft: "10px" }}
                      >
                        Stripe
                      </label>
                      <p data-method="option5" className="payment-text">
                        Pay via Stripe
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-danger checkout-btn"
                  onClick={handleCheckout}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </>
                  ) : (
                    "Place Order"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Checkout;
