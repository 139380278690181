import React from "react";
import useFetch from "../../../../Hooks/UseFetch";

const Second_Banner = () => {
  const { data, loading, error, reFetch } = useFetch(
    "/banners/6700ee6f76ee2d141d8449b7"
  );

  const banner = data;

  return (
    <>
      {banner && (
        <div
          className="offers mt-5 mb-5"
          style={{
            background: `url(${banner.photo}) center/cover no-repeat`,
            width: "100%",
            height: "450px",
          }}
        >
          <div className="container">
            <div className="row d-flex align-items-center justify-content-center offers-details text-center justify-content-lg-start text-lg-start">
              <div className="offers-content">
                <span className="text-white">{banner.subtitle}</span>
                <h2 className="mt-2 mb-4 text-white">{banner.title}</h2>
                <div className="slider-btn">
                  <a href={banner.link} className="btn">
                    Buy Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Second_Banner;
