import React from "react";
import "./skeleton.css";

const Skeleton = () => {
  const skeletons = [];
  for (let i = 0; i < 3; i++) {
    skeletons.push(
      <div className="col-lg-4" key={i}>
        <div className="loader-container2">
          <div className="loader2">
            <div className="image skeleton "></div>
            <div className="text skeleton"></div>
            <div className="text skeleton"></div>
            <div className="text skeleton half"></div>
          </div>
          <div className="loader2">
            <div className="image skeleton"></div>
            <div className="text skeleton"></div>
            <div className="text skeleton"></div>
            <div className="text skeleton half"></div>
          </div>
          <div className="loader2">
            <div className="image skeleton"></div>
            <div className="text skeleton"></div>
            <div className="text skeleton"></div>
            <div className="text skeleton half"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="row">{skeletons}</div>
    </>
  );
};

export default Skeleton;
