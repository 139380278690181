import { useEffect, useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Navigation from "../../Components/Navigation/Navigation";
import Newsletter from "../../Components/Newsletter/Newsletter";

const About = ({ about }) => {
  const [aboutData, setAboutData] = useState(about);

  return (
    <div>
      <Navigation />
      <div>
        <div className="section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="about_img scene mb-4 mb-lg-0">
                  <img src={aboutData.photo} alt="about_img" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="heading_s1">
                  <h2>{aboutData.title}</h2>
                </div>
                <p>{aboutData.text}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <Footer />
    </div>
  );
};

export default About;
