import React from "react";

const ColorFilter = ({ handleColorSelection, selectedColor }) => {
  const colors = [
    { name: "Brown", code: "#87554B" },
    { name: "Black", code: "#333333" },
    { name: "Red", code: "#DA323F" },
    { name: "Blue", code: "#2F366C" },
    { name: "Gray", code: "#B5B6BB" },
    { name: "Cyan", code: "#5FB7D4" },
    { name: "Dark Blue", code: "#2F366B" },
  ];

  return (
    <div className="widget py-2">
      <h5 className="widget_title">Color</h5>
      <div className="product_color_switch">
        {colors.map((color) => (
          <span
            key={color.code}
            onClick={() => handleColorSelection(color.code)}
            className={`product_color ${
              selectedColor === color.code ? "selected" : ""
            }`}
            style={{ backgroundColor: color.code }}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default ColorFilter;
