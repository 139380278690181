import React from "react";

const ShopBanner = ({ banner }) => {
  return (
    <div className="widget">
      <div className="shop_banner py-3 position-relative">
        <div className="banner_img overlay_bg_20">
          <img src={banner.photo} alt="sidebar_banner_img" />
        </div>
        <div className="shop_btn text_white position-absolute">
          <h5 className="text-uppercase shop_subtitle">{banner.subtitle}</h5>
          <h3 className="text-uppercase shop_title">{banner.title}</h3>
          <a
            href={banner.link}
            className="btn btn-white rounded-0 btn-sm text-uppercase"
          >
            Shop Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default ShopBanner;
