import { Link } from "react-router-dom";
import useGlobalStore from "../../Store/Store";
import TopNavigation from "../TopNavigation/TopNavigation";

const Navigation = () => {
  const { cart, wishlist, compare } = useGlobalStore();

  const cartCount = cart.length;
  const wishlistCount = wishlist.length;
  const compareCount = compare.length;

  const mylogo = "/assets/images/mylogo.png";
  return (
    <div>
      <TopNavigation />
      <nav className="navbar navbar-expand-lg navbar-light bg-white py-2">
        <div className="container">
          <Link
            className="navbar-brand order-lg-0 d-flex justify-content-between align-items-center"
            to="/"
          >
            <img src={mylogo} alt="logo" />
          </Link>

          <div
            className="nav-btns order-lg-2"
            style={{ marginTop: "10px", marginRight: "30px" }}
          >
            {/* <Link className="position-relative" to="/compare">
              <i className="icon-shuffle"></i>
              <span className="position-absolute count">{compareCount}</span>
            </Link> */}
            <Link className="position-relative" to="/wishlist">
              <i className="icon-heart"></i>
              <span className="position-absolute count">{wishlistCount}</span>
            </Link>
            <Link className="position-relative" to="/cart">
              <i className="icon-basket-loaded"></i>
              <span className="position-absolute count">{cartCount}</span>
            </Link>
          </div>

          <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navMenu"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse order-lg-1 navbar-collapse" id="navMenu">
            <ul className="navbar-nav mx-auto text-center">
              <li className="nav-item px-2 py-2">
                <Link className="nav-link text-uppercase" to="/">
                  home
                </Link>
              </li>
              <li className="nav-item px-2 py-2">
                <Link className="nav-link text-uppercase" to="/shoppage">
                  Shop
                </Link>
              </li>
              <li className="nav-item px-2 py-2">
                <Link className="nav-link text-uppercase" to="/checkout">
                  Checkout
                </Link>
              </li>
              <li className="nav-item px-2 py-2">
                <Link className="nav-link text-uppercase" to="/about">
                  about
                </Link>
              </li>
              <li className="nav-item px-2 py-2">
                <Link className="nav-link text-uppercase" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
