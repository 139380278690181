import React, { useState, useEffect } from "react";
import Navigation from "../../Components/Navigation/Navigation";
import Newsletter from "../../Components/Newsletter/Newsletter";
import Footer from "../../Components/Footer/Footer";
import API from "../../Services/API";
import { toast } from "react-hot-toast";
import useGlobalStore from "../../Store/Store";
const Contact = () => {
  const [settings, setSettings] = useState({
    address: "",
    email: "",
    phone: "",
    map: "",
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  // Fetch settings from the backend when component mounts
  useEffect(() => {
    API.get("/userSettings")
      .then((response) => {
        if (response.data) {
          // Update state with fetched settings
          setSettings(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching settings:", error);
      });
  }, []);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send the form data to the backend
      const response = await API.post("/messages", formData);
      // Optionally, reset the form after successful submission
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });

      toast.success("Message sent Successfully!");
    } catch (error) {
      console.error("Error sending message:", error);
      // Display an error message to the user
      alert("Failed to send message. Please try again later.");
    }
  };

  return (
    <div>
      <Navigation />
      <div className="section pb_70">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-md-6 icon_box_style1">
              <div className="contact_wrap contact_style3">
                <div className="contact_icon">
                  <i className="linearicons-map2"></i>
                </div>
                <div className="contact_text">
                  <span>Address</span>
                  <p>{settings.address}</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 icon_box_style1">
              <div className="contact_wrap contact_style3">
                <div className="contact_icon">
                  <i className="linearicons-envelope-open"></i>
                </div>
                <div className="contact_text">
                  <span>Email Address</span>
                  <p>{settings.email}</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="contact_wrap contact_style3">
                <div className="contact_icon">
                  <i className="linearicons-tablet2"></i>
                </div>
                <div className="contact_text">
                  <span>Phone</span>
                  <p>{settings.phone}</p>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>

      <div className="section pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="heading_s1">
                <h2>Get In touch</h2>
              </div>
              <p className="leads">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Phasellus blandit massa enim. Nullam id varius nunc id varius
                nunc.
              </p>
              <div className="field_form">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <input
                        required
                        placeholder="Enter Name *"
                        id="name"
                        className="form-control"
                        name="name"
                        type="text"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        required
                        placeholder="Enter Email *"
                        id="email"
                        className="form-control"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        required
                        placeholder="Enter Phone No. *"
                        id="phone"
                        className="form-control"
                        name="phone"
                        type="tel"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        placeholder="Enter Subject"
                        id="subject"
                        className="form-control"
                        name="subject"
                        type="text"
                        value={formData.subject}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <textarea
                        required
                        placeholder="Message *"
                        id="message"
                        className="form-control"
                        name="message"
                        rows="4"
                        value={formData.message}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                    <div className="col-md-12">
                      <button
                        type="submit"
                        title="Submit Your Message!"
                        className="btn btn-danger"
                        id="submitButton"
                        name="submit"
                        value="Submit"
                      >
                        Send Message
                      </button>
                    </div>
                    <div className="col-md-12">
                      <div
                        id="alert-msg"
                        className="alert-msg text-center"
                      ></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 pt-2 pt-lg-0 mt-4 mt-lg-0">
              <div id="map" className="contact_map2">
                <iframe
                  src={settings.map}
                  title="Location Map"
                  frameBorder="0"
                  width="100%"
                  height="100%"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Contact;
