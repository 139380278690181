import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useFetch from "../../Hooks/UseFetch";
import { toast } from "react-hot-toast";
import useGlobalStore from "../../Store/Store";
import ProductCard from "../shared/ProductCard/ProductCard";
import ProductModal from "../Modal/ProductModal";
import CountdownTimer from "../../Components/CountDownTimer/CountdownTimer";

import axios from "axios";
import API from "../../Services/API";

const FlashSale = ({ productData }) => {
  const { wishlist, addToWishlist, cart, addToCart, compare, addToCompare } =
    useGlobalStore();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [popularProducts, setPopularProducts] = useState([]);
  const [saleSettings, setSaleSettings] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isSaleActive, setIsSaleActive] = useState(false);

  useEffect(() => {
    if (!productData.loading && !productData.error && productData.length > 0) {
      // Filter products that have sale_price
      const productsWithSalePrice = productData.filter(
        (product) => product.sale_price
      );
      // Shuffle the array of products with sale_price to get random products
      const shuffledProducts = productsWithSalePrice.sort(
        () => 0.5 - Math.random()
      );
      setPopularProducts(shuffledProducts);
    }
  }, [productData]);

  useEffect(() => {
    API.get("/salesettings")
      .then((response) => {
        const { expiry_date, status } = response.data;
        if (status === "active" && new Date(expiry_date) > new Date()) {
          const endTime = new Date(expiry_date);
          setEndTime(endTime);
          setIsSaleActive(true);
        } else {
          setIsSaleActive(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching sale settings:", error);
      });
  }, []);

  const handleAddToCompare = (event, product) => {
    event.preventDefault();
    if (!isInCompare(product._id)) {
      addToCompare(product);
      toast.success("Product added to Compare");
    } else {
      toast.error("Product is already in Compare");
    }
  };

  const handleAddToCart = (product, selectedColor) => {
    const productWithColor = { ...product, color: selectedColor };
    if (!isInCart(product._id)) {
      addToCart(productWithColor);
      toast.success("Product added to Cart");
    } else {
      toast.error("Product is already in Cart");
    }
  };

  const handleAddToWishlist = (event, product) => {
    event.preventDefault();
    if (!isInWishlist(product._id)) {
      addToWishlist(product);
      toast.success("Product added to Wishlist");
    } else {
      toast.error("Product is already in Wishlist");
    }
  };

  const isInCompare = (productId) =>
    compare && compare.some((item) => item._id === productId);
  const isInCart = (productId) => cart.some((item) => item._id === productId);
  const isInWishlist = (productId) =>
    wishlist.some((item) => item._id === productId);

  const openModal = (event, product) => {
    event.preventDefault();
    setSelectedProduct(product);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  if (!isSaleActive) {
    return null; // Return null to render nothing if sale is not active
  }

  const PrevArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <i className="ion-ios-arrow-left"></i>
    </div>
  );

  const NextArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <i className="ion-ios-arrow-right"></i>
    </div>
  );

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 910,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 670,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <div className="container">
      <div className="row gx-1">
        <div className="col-md-12 title pt-3 flash-sale">
          <div>
            <h2 className="heading-h2">On Sale</h2>
          </div>
          <div className="deal_timer">
            <div>
              <CountdownTimer endTime={endTime} />
            </div>
          </div>
        </div>
        <Slider {...settings}>
          {popularProducts.map((product) => (
            <div key={product._id} className="col-md-3">
              <ProductCard
                product={product}
                handleAddToCart={handleAddToCart}
                handleAddToCompare={handleAddToCompare}
                handleAddToWishlist={handleAddToWishlist}
                isInWishlist={isInWishlist}
                isInCompare={isInCompare}
                openModal={openModal}
              />
            </div>
          ))}
        </Slider>
      </div>
      {selectedProduct && (
        <ProductModal product={selectedProduct} closeModal={closeModal} />
      )}
    </div>
  );
};

export default FlashSale;
