import React, { useEffect, useState } from "react";

const CountdownTimer = ({ endTime }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(endTime) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="countdown-container">
      <span className="countdown-box">
        <span className="countdown days">{timeLeft.days} :</span>
      </span>
      <span className="countdown-box">
        <span className="countdown hours">{timeLeft.hours}</span>
      </span>
      <span className="countdown-box">
        <span className="countdown minutes">: {timeLeft.minutes} :</span>
      </span>
      <span className="countdown-box">
        <span className="countdown seconds">{timeLeft.seconds}</span>
      </span>
    </div>
  );
};

export default CountdownTimer;
