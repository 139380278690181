import React, { useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import Navigation from "../../Components/Navigation/Navigation";
import Newsletter from "../../Components/Newsletter/Newsletter";
import useGlobalStore from "../../Store/Store";

const Wishlist = () => {
  const { wishlist, cart, addToCart, removeFromWishlist } = useGlobalStore();

  useEffect(() => {
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
  }, [wishlist]);

  const handleAddToCart = (product) => {
    const isItemInCart = cart.some((item) => item._id === product._id);
    if (!isItemInCart) {
      addToCart(product);
      removeFromWishlist(product._id);
    }
  };

  const handleRemoveFromWishlist = (productId) => {
    removeFromWishlist(productId);
  };

  return (
    <>
      <Navigation />
      <div className="container" style={{ minHeight: "300px" }}>
        {wishlist.length > 0 ? ( // Check if there are items in the wishlist
          <div className="row wishlist">
            <div className="col-md-12">
              <h3 className="mt-5">Wishlist</h3>
              <table className="table mb-4">
                <thead>
                  <tr>
                    <th>IMAGES</th>
                    <th>PRODUCT</th>
                    <th>UNIT PRICE</th>
                    <th>STOCK STATUS</th>
                    <th>ACTIONS</th>
                    <th style={{ width: "100px" }}>REMOVE</th>
                  </tr>
                </thead>
                <tbody>
                  {wishlist.map((item) => (
                    <tr style={{ verticalAlign: "middle" }} key={item._id}>
                      <td className="product-thumbnail">
                        <a href="">
                          <img
                            style={{ width: "60px" }}
                            src={item.photo}
                            alt={item.title}
                          />
                        </a>
                      </td>
                      <td className="product-name">
                        <h6>{item.title}</h6>
                      </td>
                      <td
                        className="product-price fw-bold text-danger"
                        style={{ fontSize: "16px" }}
                      >
                        $
                        {item.sale_price ? item.sale_price : item.regular_price}
                        .00
                      </td>
                      <td className="product-status">In Stock</td>
                      <td className="product-action">
                        <button
                          className="btn btn-danger"
                          onClick={() => handleAddToCart(item)}
                        >
                          <i className="icon-basket-loaded"></i>
                          Add to Cart
                        </button>
                      </td>
                      <td className="text-center">
                        <a
                          href="#"
                          className="close-btn"
                          onClick={(event) =>
                            handleRemoveFromWishlist(item._id, event)
                          }
                        >
                          <i className="ti-close"></i>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="row py-5 no-wishlist">
            <img src="assets/images/empty-wishlist.png" alt="no-wishlist" />
            <h3>No Item in Wishlist</h3>
          </div>
        )}
      </div>
      <Newsletter />
      <Footer />
    </>
  );
};

export default Wishlist;
