import API from "../../Services/API";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import useGlobalStore from "../../Store/Store";
import Footer from "../../Components/Footer/Footer";
import React, { useState, useEffect, useMemo } from "react";
import LabelFilter from "../../Components/LabelFilter/LabelFilter";
import ProductModal from "../../Components/Modal/ProductModal";
import SizeFilter from "../../Components/SizeFilter/SizeFilter";
import ShopBanner from "../../Components/ShopBanner/ShopBanner";
import Pagination from "../../Components/Pagination/Pagination";
import Newsletter from "../../Components/Newsletter/Newsletter";
import Navigation from "../../Components/Navigation/Navigation";
import ColorFilter from "../../Components/ColorFilter/ColorFilter";
import PriceFilter from "../../Components/PriceFilter/PriceFilter";
import CategoriesFilter from "../../Components/CategoriesFilter/CategoriesFilter";
import ProductHeaderLeft from "../../Components/ProductHeaderLeft/ProductHeaderLeft";
import ProductHeaderRight from "../../Components/ProductHeaderRight/ProductHeaderRight";
import Skeleton from "../../Components/shared/skeleton/Skeleton";

const ShopPage = ({ productData, bannerData, categories }) => {
  const [banner, setBanner] = useState(bannerData);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [hasSalePrice, setHasSalePrice] = useState(true);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedColor, setSelectedColor] = useState(null);
  const [sortingOption, setSortingOption] = useState("order");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState([]);
  const { wishlist, addToWishlist, cart, addToCart, compare, addToCompare } =
    useGlobalStore();

  const currentProducts = useMemo(() => {
    const productsCopy = [...productData];

    const filtered = productsCopy.filter((product) => {
      const isCategoryMatched =
        !selectedCategory || product.category === selectedCategory;
      const isSubcategoryMatched =
        !selectedSubcategory || product.subcategory === selectedSubcategory;
      const isPriceInRange =
        parseFloat(product.regular_price) >= minPrice &&
        parseFloat(product.regular_price) <= maxPrice;

      const isSizeMatched =
        selectedSizes.length === 0 ||
        !product.sizes ||
        selectedSizes.every((size) => product.sizes.includes(size));

      const isLabelMatched = !selectedLabel || product.label === selectedLabel;

      return (
        isCategoryMatched &&
        isSubcategoryMatched &&
        isPriceInRange &&
        isSizeMatched &&
        isLabelMatched
      );
    });

    return filtered;
  }, [
    productData,
    selectedCategory,
    selectedSubcategory,
    minPrice,
    maxPrice,
    selectedSizes,
    selectedLabel,
  ]);

  useEffect(() => {
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
  }, [wishlist]);

  useEffect(() => {
    localStorage.setItem("compare", JSON.stringify(compare));
  }, [compare]);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const handleFilterChange = (min, max) => {
    setMinPrice(min);
    setMaxPrice(max);
  };

  const handleLabelSelection = (label) => {
    setSelectedLabel(label === selectedLabel ? null : label);
  };

  const filteredProducts = useMemo(() => {
    if (!selectedLabel || selectedLabel.length === 0) {
      return productData;
    }
    return productData.filter((product) =>
      selectedLabel.includes(product.label)
    );
  }, [productData, selectedLabel]);

  const handleAddToCompare = (event, product) => {
    event.preventDefault();
    if (!isInCompare(product._id)) {
      addToCompare(product);
      toast.success("Product added to Compare");
    } else {
      toast.error("Product is already in Compare");
    }
  };

  const handleAddToCart = (event, product) => {
    event.preventDefault();

    if (!isInCart(product._id)) {
      const productWithColor = { ...product, color: selectedColor };
      addToCart(productWithColor);
      toast.success("Product added to Cart");
    } else {
      toast.error("Product is already in Cart");
    }
  };

  const handleAddToWishlist = (event, product) => {
    event.preventDefault();
    if (!isInWishlist(product._id)) {
      addToWishlist(product);
      toast.success("Product added to Wishlist");
    } else {
      toast.error("Product is already in Wishlist");
    }
  };

  const isInCompare = useMemo(() => {
    return (productId) =>
      compare && compare.some((item) => item._id === productId);
  }, [compare]);

  const isInCart = useMemo(() => {
    return (productId) => cart && cart.some((item) => item._id === productId);
  }, [cart]);

  const isInWishlist = useMemo(() => {
    return (productId) =>
      wishlist && wishlist.some((item) => item._id === productId);
  }, [wishlist]);

  const [viewMode, setViewMode] = useState("grid");

  const openModal = (event, product) => {
    event.preventDefault();
    setSelectedProduct(product);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  const switchToGrid = () => {
    setViewMode("grid");
  };

  const switchToList = () => {
    setViewMode("list");
  };

  const totalPages = Math.ceil(currentProducts.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProductsPage = currentProducts.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const sortedProducts = useMemo(() => {
    const productsCopy = [...currentProducts];

    switch (sortingOption) {
      case "popularity":
        // Implement popularity sorting
        break;
      case "date":
        // Implement date sorting
        break;
      case "price":
        productsCopy.sort(
          (a, b) => parseFloat(a.sale_price) - parseFloat(b.sale_price)
        );
        break;
      case "price-desc":
        productsCopy.sort(
          (a, b) => parseFloat(b.sale_price) - parseFloat(a.sale_price)
        );
        break;
      default:
        break;
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const slicedProducts = productsCopy.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    return slicedProducts;
  }, [currentProducts, currentPage, itemsPerPage, sortingOption]);

  const handleSizeSelection = (size) => {
    setSelectedSizes((prevSelectedSizes) => {
      const index = prevSelectedSizes.indexOf(size);
      if (index === -1) {
        return [...prevSelectedSizes, size];
      } else {
        const updatedSizes = [...prevSelectedSizes];
        updatedSizes.splice(index, 1);
        return updatedSizes;
      }
    });
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
    setSelectedSubcategory(null);
  };

  const handleSubcategoryClick = (subcategoryId) => {
    setSelectedSubcategory(subcategoryId);
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handleSortingChange = (event) => {
    const newSortingOption = event.target.value;
    setSortingOption(newSortingOption);
  };

  const paginate = (pageNumber) => {
    const totalPages = Math.ceil(currentProducts.length / itemsPerPage);

    if (pageNumber < 1 || pageNumber > totalPages) {
      return;
    }

    setCurrentPage(pageNumber);
  };

  const handleColorSelection = (colorCode) => {
    setSelectedColor(colorCode);
  };

  return (
    <div>
      <Navigation />
      <div className="container py-5">
        <div className="row">
          <div className="col-md-3">
            <div className="container ">
              <div className="row">
                <div className="sidebar gx-1">
                  <CategoriesFilter
                    categories={categories}
                    expandedCategories={expandedCategories}
                    handleCategoryClick={handleCategoryClick}
                    handleSubcategoryClick={handleSubcategoryClick}
                  />
                  <hr />
                  <div className="widget py-2">
                    <h5 className="widget_title">Filter</h5>
                    <div className="w-100">
                      <PriceFilter
                        className="gx-2"
                        minPrice={0}
                        maxPrice={500}
                        onFilterChange={handleFilterChange}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="widget">
                    <h5 className="widget_title">Size</h5>
                    <div className="w-100 pb-1">
                      <SizeFilter
                        selectedSizes={selectedSizes}
                        handleSizeSelection={handleSizeSelection}
                      />
                    </div>
                  </div>
                  <hr />
                  <ColorFilter
                    handleColorSelection={handleColorSelection}
                    selectedColor={selectedColor}
                  />
                  <hr />
                  <LabelFilter
                    handleLabelSelection={handleLabelSelection}
                    selectedLabel={selectedLabel}
                  />
                  <hr />
                  {banner && <ShopBanner banner={banner} />}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="row align-items-center mb-4 pb-1">
              <div className="col-12">
                <div className="product_header">
                  <ProductHeaderLeft
                    sortingOption={sortingOption}
                    handleSortingChange={handleSortingChange}
                  />
                  <ProductHeaderRight
                    viewMode={viewMode}
                    switchToGrid={switchToGrid}
                    switchToList={switchToList}
                    itemsPerPage={itemsPerPage}
                    handleItemsPerPageChange={handleItemsPerPageChange}
                  />
                </div>
              </div>
            </div>

            {productData.loading ? (
              <Skeleton />
            ) : (
              <div className={`row shop_container ${viewMode}`}>
                {sortedProducts.map((product) => {
                  const regularPrice = parseFloat(product.regular_price);
                  const salePrice = parseFloat(product.sale_price);
                  const discountPercenlabele =
                    ((regularPrice - salePrice) / regularPrice) * 100;

                  let labelClass = "";

                  switch (product.label) {
                    case "NEW":
                      labelClass = "bg-warning";
                      break;
                    case "HOT":
                      labelClass = "bg-danger";
                      break;
                    case "SALE":
                      labelClass = "bg-success";
                      break;
                    default:
                      labelClass = "";
                  }

                  return (
                    <div key={product._id} className="col-md-4 col-6">
                      <div className="product card">
                        <div className="product_img">
                          <a href="shop-product-detail.html">
                            <img src={product.photo} alt={product.title} />
                          </a>
                          <div className={`pr-flash ${labelClass}`}>
                            {product.label}
                          </div>
                          <div className="product_action_box">
                            <ul className="pr_action_btn">
                              <li>
                                <a
                                  href="#"
                                  onClick={(event) =>
                                    handleAddToCart(event, product)
                                  }
                                >
                                  <i
                                    className={
                                      isInWishlist(product._id)
                                        ? "icon-basket-loaded active"
                                        : "icon-basket-loaded"
                                    }
                                  ></i>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  onClick={(event) =>
                                    handleAddToCompare(event, product)
                                  }
                                >
                                  <i
                                    className={
                                      isInCompare(product._id)
                                        ? "icon-shuffle active"
                                        : "icon-shuffle"
                                    }
                                  ></i>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  onClick={(event) => openModal(event, product)}
                                  className="view-product"
                                >
                                  <i className="icon-magnifier-add"></i>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  onClick={(event) =>
                                    handleAddToWishlist(event, product)
                                  }
                                >
                                  <i
                                    className={
                                      isInWishlist(product._id)
                                        ? "icon-heart active"
                                        : "icon-heart"
                                    }
                                  ></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="product_info card-body">
                          <h6 className="product_title card-title">
                            <Link to={`/product-details/${product._id}`}>
                              {product.title}
                            </Link>
                          </h6>
                          <div
                            className="product_price d-flex"
                            style={{ gap: "10px" }}
                          >
                            <span className="fw-bold text-danger">
                              $
                              {product.sale_price
                                ? product.sale_price
                                : product.regular_price}
                              .00
                            </span>
                            {product.sale_price && (
                              <>
                                <del>${product.regular_price}.00</del>
                                <div className="on_sale">
                                  <span>
                                    {`${Math.round(
                                      ((product.regular_price -
                                        product.sale_price) /
                                        product.regular_price) *
                                        100
                                    )}% Off`}
                                  </span>
                                </div>
                              </>
                            )}
                          </div>

                          <div className="rating_wrap">
                            <div className="rating">
                              <div
                                className="product_rate"
                                style={{ width: "80%" }}
                              ></div>
                            </div>
                            <span className="rating_num">(21)</span>
                          </div>
                          <div className="pr_desc">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Phasellus blandit massa enim. Nullam id
                              varius nunc id varius nunc.
                            </p>
                          </div>
                          <div className="pr_switch_wrap">
                            <div className="product_color_switch">
                              {product.colors.map((color, index) => (
                                <span
                                  key={index}
                                  className={
                                    color === selectedColor ? "active" : ""
                                  }
                                  data-color={color}
                                  onClick={() => handleColorSelection(color)}
                                  style={{ backgroundColor: color }}
                                ></span>
                              ))}
                            </div>
                          </div>
                          <div className="list_product_action_box">
                            <ul className="list_none pr_action_btn">
                              <li className="add-to-cart">
                                <a
                                  className="bg-danger text-white"
                                  href="#"
                                  onClick={(event) =>
                                    handleAddToCart(event, product)
                                  }
                                >
                                  <i
                                    className={
                                      isInWishlist(product._id)
                                        ? "icon-basket-loaded active"
                                        : "icon-basket-loaded"
                                    }
                                  ></i>
                                  Add To Cart
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  onClick={(event) =>
                                    handleAddToCompare(event, product)
                                  }
                                >
                                  <i
                                    className={
                                      isInCompare(product._id)
                                        ? "icon-shuffle active"
                                        : "icon-shuffle"
                                    }
                                  ></i>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  onClick={(event) => openModal(event, product)}
                                  className="view-product"
                                >
                                  <i className="icon-magnifier-add"></i>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  onClick={(event) =>
                                    handleAddToWishlist(event, product)
                                  }
                                >
                                  <i
                                    className={
                                      isInWishlist(product._id)
                                        ? "icon-heart active"
                                        : "icon-heart"
                                    }
                                  ></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            </div>

            {selectedProduct && (
              <ProductModal product={selectedProduct} closeModal={closeModal} />
            )}
          </div>
        </div>
      </div>
      <Newsletter />
      <Footer />
    </div>
  );
};

export default ShopPage;
