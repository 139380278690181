// ProductHeaderRight.js

import React from "react";
import "./productHeaderRight.scss";

const ProductHeaderRight = ({
  viewMode,
  switchToGrid,
  switchToList,
  itemsPerPage,
  handleItemsPerPageChange,
}) => {
  return (
    <div className="product_header_right">
      <div className="products_view">
        <a
          href="#"
          className={`shorting_icon grid ${
            viewMode === "grid" ? "active" : ""
          }`}
          onClick={switchToGrid}
        >
          <i className="ti-view-grid"></i>
        </a>
        <a
          href="#"
          className={`shorting_icon list ${
            viewMode === "list" ? "active" : ""
          }`}
          onClick={switchToList}
        >
          <i className="ti-layout-list-thumb"></i>
        </a>
      </div>
      <div className="custom_select">
        <select
          className="form-control form-control-sm first_null not_chosen"
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
        >
          <option value="">Showing</option>
          <option value="9">9</option>
          <option value="12">12</option>
          <option value="18">18</option>
        </select>
      </div>
    </div>
  );
};

export default ProductHeaderRight;
