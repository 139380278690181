import { useState } from "react";
import { Link } from "react-router-dom";
import "./productCard.css";

const ProductCard = ({
  product,
  handleAddToCart,
  handleAddToCompare,
  handleAddToWishlist,
  isInWishlist,
  isInCompare,
  openModal,
}) => {
  const [selectedColor, setSelectedColor] = useState("");

  const regularPrice = parseFloat(product.regular_price);
  const salePrice = parseFloat(product.sale_price);
  const hasSalePrice = !isNaN(salePrice);
  let discountPercentage = 0;

  if (hasSalePrice) {
    discountPercentage = ((regularPrice - salePrice) / regularPrice) * 100;
  }

  const handleAddToCartClick = (event) => {
    event.preventDefault();
    handleAddToCart(product, selectedColor);
  };

  const handleColorClick = (color) => {
    setSelectedColor(color);
  };

  let labelClass = "";

  switch (product.label) {
    case "NEW":
      labelClass = "bg-warning";
      break;
    case "HOT":
      labelClass = "bg-danger";
      break;
    case "SALE":
      labelClass = "bg-success";
      break;
    default:
      labelClass = "";
  }

  return (
    <div className="card product" tabIndex="0">
      <div className="product_img">
        <a href="shop-product-detail.html">
          <img src={product.photo} alt={product.title} />
        </a>
        <div className={`pr-flash ${labelClass}`}>{product.label}</div>
        <div className="product_action_box">
          <ul className="pr_action_btn">
            <li>
              <a
                href="#"
                onClick={(event) => handleAddToCartClick(event, product)}
              >
                <i
                  className={
                    isInWishlist(product._id)
                      ? "icon-basket-loaded active"
                      : "icon-basket-loaded"
                  }
                ></i>
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={(event) => handleAddToCompare(event, product)}
              >
                <i
                  className={
                    isInCompare(product._id)
                      ? "icon-shuffle active"
                      : "icon-shuffle"
                  }
                ></i>
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={(event) => openModal(event, product)}
                className="view-product"
              >
                <i className="icon-magnifier-add"></i>
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={(event) => handleAddToWishlist(event, product)}
              >
                <i
                  className={
                    isInWishlist(product._id)
                      ? "icon-heart active"
                      : "icon-heart"
                  }
                ></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="card-body product_info">
        <h6 className="product_title card-title">
          <Link to={`/product-details/${product._id}`}>{product.title}</Link>
        </h6>
        <div className="product_price d-flex" style={{ gap: "10px" }}>
          {hasSalePrice && (
            <>
              <span className="fw-bold text-danger">
                ${product.sale_price}.00
              </span>
              <del>${product.regular_price}.00</del>
            </>
          )}
          {!hasSalePrice && (
            <span className="fw-bold text-danger">
              ${product.regular_price}.00
            </span>
          )}
          {hasSalePrice && (
            <div className="on_sale">
              <span>{`${Math.round(discountPercentage)}% Off`}</span>
            </div>
          )}
        </div>
        <div className="rating_wrap">
          <div className="rating">
            <div className="product_rate" style={{ width: "80%" }}></div>
          </div>
          <span className="rating_num">(21)</span>
        </div>
        <div className="pr_switch_wrap">
          <div className="product_color_switch">
            {product.colors.map((color, index) => (
              <span
                key={index}
                className={selectedColor === color ? "active" : ""}
                data-color={color}
                onClick={() => handleColorClick(color)}
                style={{ backgroundColor: color }}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
