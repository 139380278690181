import React from "react";
import { Link } from "react-router-dom";
import useGlobalStore from "../../Store/Store";

const TopNavigation = () => {
  const { isLoggedIn, logout } = useGlobalStore();
  return (
    <div>
      <div className="top-navigation">
        <div className="container">
          <div className="row top-navigation-body">
            <div className="col left-section">
              <ul>
                <li>
                  <Link to="/contact">
                    <i className="ti-mobile"></i>
                    <span>123-456-7890</span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col right-section">
              <ul>
                <li>
                  <Link to="/compare">
                    <i className="icon-shuffle"></i>
                    <span>Compare</span>
                  </Link>
                </li>
                <li>
                  {isLoggedIn ? (
                    <button onClick={logout}>
                      <i className="ti-power-off"></i>
                      <span>Logout</span>
                    </button>
                  ) : (
                    <Link to="/login">
                      <i className="ti-user"></i>
                      <span>Login</span>
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNavigation;
