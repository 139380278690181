import React, { useState } from "react";
import useGlobalStore from "../../Store/Store";
import { Link } from "react-router-dom";
import Newsletter from "../../Components/Newsletter/Newsletter";
import Footer from "../../Components/Footer/Footer";
import Navigation from "../../Components/Navigation/Navigation";

const Cart = () => {
  const {
    cart,
    removeFromCart,
    increaseQuantity,
    decreaseQuantity,
    updateItemColor,
    updateItemSize,
  } = useGlobalStore();
  const [error, setError] = useState(null);

  const calculateItemTotal = (item) => {
    if (item.sale_price) {
      return item.sale_price * (item.quantity || 1);
    } else {
      return item.regular_price * (item.quantity || 1);
    }
  };

  const total = cart.reduce(
    (totalPrice, item) => totalPrice + calculateItemTotal(item),
    0
  );

  const handleRemoveFromCart = (itemId, event) => {
    event.preventDefault();
    removeFromCart(itemId);
  };

  const handleColorClick = (itemId, color) => {
    updateItemColor(itemId, color);
  };

  const handleSizeClick = (itemId, size) => {
    updateItemSize(itemId, size);
  };

  return (
    <div>
      <Navigation />
      <div className="container">
        {cart && cart.length > 0 ? (
          <>
            <h3 className="card-heading mt-5 mb-5 text-center">Cart</h3>
            <div className="row">
              <div className="col">
                <div className="table-responsive">
                  {cart && cart.length > 0 ? (
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Product</th>
                          <th>Price</th>
                          <th>Variation</th>
                          <th style={{ width: "100px" }}>Quantity</th>
                          <th>Total</th>
                          <th style={{ width: "75px" }}>Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cart.map((item) => (
                          <tr
                            key={item._id}
                            style={{ verticalAlign: "middle" }}
                          >
                            <td>
                              <img
                                src={item.photo}
                                alt={item.title}
                                style={{ width: "60px" }}
                              />
                            </td>
                            <td>
                              <Link to={`/product-details/${item._id}`}>
                                {item.title}
                              </Link>
                            </td>
                            <td
                              className="fw-bold"
                              style={{ color: "#fd3550" }}
                            >
                              $
                              {item.sale_price
                                ? item.sale_price
                                : item.regular_price}
                              .00
                            </td>
                            <td>
                              <div className="product_color_switch">
                                {Array.isArray(item.colors) ? (
                                  item.colors.map((color, index) => (
                                    <span
                                      key={index}
                                      className={`color-swatch ${
                                        color === item.color ? "active" : ""
                                      }`}
                                      data-color={color}
                                      style={{ backgroundColor: color }}
                                      onClick={() =>
                                        handleColorClick(item._id, color)
                                      }
                                    ></span>
                                  ))
                                ) : (
                                  <span>{item.colors}</span>
                                )}
                              </div>
                              <div className="sizes-box">
                                {Array.isArray(item.sizes) ? (
                                  item.sizes.map((size, index) => (
                                    <span
                                      key={index}
                                      className={`size-swatch ${
                                        size === item.size ? "active" : ""
                                      }`}
                                      onClick={() =>
                                        handleSizeClick(item._id, size)
                                      }
                                    >
                                      {size}
                                    </span>
                                  ))
                                ) : (
                                  <span>{item.sizes}</span>
                                )}
                              </div>
                            </td>
                            <td>
                              <button
                                className="cart-btn"
                                onClick={() => decreaseQuantity(item._id)}
                              >
                                -
                              </button>
                              <span style={{ margin: "0 10px" }}>
                                {item.quantity}
                              </span>
                              <button
                                className="cart-btn"
                                onClick={() => increaseQuantity(item._id)}
                              >
                                +
                              </button>
                            </td>
                            <td className="fw-bold">
                              ${calculateItemTotal(item).toFixed(2)}
                            </td>
                            <td className="text-center">
                              <button
                                className="close-btn"
                                onClick={(event) =>
                                  handleRemoveFromCart(item._id, event)
                                }
                              >
                                <i className="ti-close"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>Your cart is empty.</p>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-3 mb-5">
              <div className="col text-end">
                <h5>
                  Total: <span id="total">${total.toFixed(2)}</span>
                </h5>
                <Link className="btn btn-danger text-white" to="/checkout">
                  Checkout <i className="icon-right-arrow"></i>
                </Link>
              </div>
            </div>
          </>
        ) : (
          <div className="row py-5 no-cart">
            <img src="assets/images/empty-cart.png" alt="no-cart" />
            <h3>No Item in Cart</h3>
          </div>
        )}
      </div>
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Cart;
