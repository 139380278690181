import React, { useEffect } from "react";
import useGlobalStore from "../../Store/Store";
import Footer from "../../Components/Footer/Footer";
import toast from "react-hot-toast";
import Navigation from "../../Components/Navigation/Navigation";
import Newsletter from "../../Components/Newsletter/Newsletter";

const Compare = () => {
  const { compare, removeFromCompare, cart, addToCart } = useGlobalStore();

  useEffect(() => {
    localStorage.setItem("compare", JSON.stringify(compare));
  }, [compare]);

  const handleAddToCart = (product) => {
    const isItemInCart = cart.some((item) => item._id === product._id);
    if (!isItemInCart) {
      addToCart(product);
      toast.success("Product moved to Cart");
      removeFromCompare(product._id);
    }
  };

  const handleRemoveFromCompare = (productId) => {
    console.log("Removing from compare:", productId);
    removeFromCompare(productId);
    toast.success("Product removed from compare");
  };

  return (
    <div>
      <Navigation />
      <div className="container py-5">
        <div className="row">
          <div className="col-md-12">
            <div className="compare_box">
              <div className="">
                <table className="table table-bordered text-center">
                  <tbody>
                    <tr className="pr_image">
                      <td className="row_title">Product Image</td>
                      {compare.map((product) => (
                        <td className="row_img" key={product._id}>
                          <img src={product.photo} alt={product.title} />
                        </td>
                      ))}
                    </tr>
                    <tr className="pr_title">
                      <td className="row_title">Product Name</td>
                      {compare.map((product) => (
                        <td className="product_name" key={product._id}>
                          <a href="shop-product-detail.html">{product.title}</a>
                        </td>
                      ))}
                    </tr>
                    <tr className="pr_price">
                      <td className="row_title">Price</td>
                      {compare.map((product) => (
                        <td className="product_price" key={product._id}>
                          <span className="price">
                            $
                            {product.sale_price
                              ? product.sale_price
                              : product.regular_price}
                            .00
                          </span>
                        </td>
                      ))}
                    </tr>
                    <tr className="pr_color">
                      <td className="row_title">Colors</td>
                      {compare.map((product) => (
                        <td className="row_color" key={product._id}>
                          <div className="product_color_switch">
                            {product.colors.map((color, colorIndex) => (
                              <span
                                key={colorIndex}
                                data-color={color}
                                style={{ backgroundColor: color }}
                              ></span>
                            ))}
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr className="pr_size">
                      <td className="row_title">Sizes</td>
                      {compare.map((product) => (
                        <td className="row_size" key={product._id}>
                          <span>{product.sizes.join(", ")}</span>
                        </td>
                      ))}
                    </tr>
                    <tr className="pr_stock">
                      <td className="row_title">Stock Status</td>
                      {compare.map((product) => (
                        <td className="row_stock" key={product._id}>
                          <span
                            className={
                              product.stock === "in_stock"
                                ? "in-stock"
                                : "out-stock"
                            }
                          >
                            {product.stock === "in_stock"
                              ? "In Stock"
                              : "Out Of Stock"}
                          </span>
                        </td>
                      ))}
                    </tr>
                    <tr className="pr_add_to_cart">
                      <td className="row_title">Add To Cart</td>
                      {compare.map((product) => (
                        <td className="row_btn" key={product._id}>
                          <button
                            className="btn btn-danger"
                            onClick={() => handleAddToCart(product)}
                          >
                            <i className="icon-basket-loaded"></i> Add To Cart
                          </button>
                        </td>
                      ))}
                    </tr>
                    <tr className="pr_remove">
                      <td className="row_title">Remove</td>
                      {compare.map((product) => (
                        <td className="row_remove" key={product._id}>
                          <button
                            onClick={() => handleRemoveFromCompare(product._id)}
                          >
                            <span>Remove</span> <i className="fa fa-times"></i>
                          </button>
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Compare;
