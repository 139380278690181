import React from "react";
import useFetch from "../../../../Hooks/UseFetch";

const LeftBanner = () => {
  const { data, loading, error, reFetch } = useFetch(
    "/banners/66fe5e009dfc51bd21cdd50f"
  );

  const banner = data;

  return (
    <>
      <div className="col-md-6 col-lg-6">
        <div className="single_banner">
          <img
            src={banner?.photo || "assets/images/shop_banner_img1.png"}
            alt="shop_banner_img1"
          />
          <div className="single_banner_info">
            {banner && (
              <>
                <h5 className="single_bn_title1">{banner.subtitle}</h5>
                <h3 className="single_bn_title">{banner.title}</h3>
              </>
            )}
            <a href="shop-left-sidebar.html" className="single_bn_link">
              Shop Now
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftBanner;
