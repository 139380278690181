import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import useGlobalStore from "./Store/Store";

const AppWithStore = () => {
  const store = useGlobalStore();
  return (
    <BrowserRouter>
      <App store={store} />
    </BrowserRouter>
  );
};

const root = createRoot(document.getElementById("root")); // Create a root
root.render(
  <React.StrictMode>
    <AppWithStore />
  </React.StrictMode>
);
