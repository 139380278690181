import React, { useState, useEffect, useMemo } from "react";
import useFetch from "../../Hooks/UseFetch";
import { toast } from "react-hot-toast";
import ProductModal from "../Modal/ProductModal";
import ProductCard from "../shared/ProductCard/ProductCard";
import useGlobalStore from "../../Store/Store";

const CollectionFilter = ({ productData }) => {
  const {
    data: collectionsData,
    loading: collectionsLoading,
    error: collectionsError,
  } = useFetch("/collections");
  const { wishlist, addToWishlist, cart, addToCart, compare, addToCompare } =
    useGlobalStore();

  const [selectedCollection, setSelectedCollection] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    if (collectionsData && collectionsData.length > 0) {
      setSelectedCollection(collectionsData[0]);
    }
  }, [collectionsData]);

  const handleTabClick = (collection) => {
    setSelectedCollection(collection);
  };

  const openModal = (event, product) => {
    event.preventDefault();
    setSelectedProduct(product);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  const isInCompare = (productId) =>
    compare.some((item) => item._id === productId);
  const isInCart = (productId) => cart.some((item) => item._id === productId);
  const isInWishlist = (productId) =>
    wishlist.some((item) => item._id === productId);

  const handleAddToCart = (product, selectedColor) => {
    const productWithColor = { ...product, color: selectedColor };
    if (!isInCart(product._id)) {
      addToCart(productWithColor);
      toast.success("Product added to Cart");
    } else {
      toast.error("Product is already in Cart");
    }
  };

  const handleAddToCompare = (event, product) => {
    event.preventDefault();
    if (!isInCompare(product._id)) {
      addToCompare(product);
      toast.success("Product added to Compare");
    } else {
      toast.error("Product is already in Compare");
    }
  };

  const handleAddToWishlist = (event, product) => {
    event.preventDefault();
    if (!isInWishlist(product._id)) {
      addToWishlist(product);
      toast.success("Product added to Wishlist");
    } else {
      toast.error("Product is already in Wishlist");
    }
  };

  const filteredProducts = useMemo(() => {
    if (Array.isArray(productData) && selectedCollection) {
      return productData.filter(
        (product) => product.product_collection === selectedCollection.slug
      );
    }
    return [];
  }, [selectedCollection, productData]);

  if (collectionsLoading) {
    return <div>Loading collections...</div>;
  }

  if (collectionsError) {
    return <div>Error loading collections: {collectionsError}</div>;
  }

  return (
    <div className="container">
      <div className="heading_s1 text-center mb-3">
        <h2>Exclusive Products</h2>
      </div>
      <ul className="nav nav-tabs">
        {collectionsData.map((collection) => (
          <li className="nav-item" key={collection._id}>
            <button
              className={`nav-link ${
                selectedCollection && selectedCollection._id === collection._id
                  ? "active"
                  : ""
              }`}
              onClick={() => handleTabClick(collection)}
            >
              {collection.name}
            </button>
          </li>
        ))}
      </ul>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 product-grid pb-3 mb-5">
        {filteredProducts.map((product) => (
          <div key={product._id} className="col-md-3">
            <ProductCard
              product={product}
              isInWishlist={isInWishlist}
              isInCompare={isInCompare}
              isInCart={isInCart}
              handleAddToCart={handleAddToCart}
              handleAddToCompare={handleAddToCompare}
              handleAddToWishlist={handleAddToWishlist}
              openModal={openModal}
            />
          </div>
        ))}
      </div>
      {selectedProduct && (
        <ProductModal product={selectedProduct} closeModal={closeModal} />
      )}
    </div>
  );
};

export default CollectionFilter;
