import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast } from "react-hot-toast";
import useGlobalStore from "../../Store/Store";
import ProductCard from "../shared/ProductCard/ProductCard";
import ProductModal from "../Modal/ProductModal";

const Popular_Products = ({ productData }) => {
  const { wishlist, addToWishlist, cart, addToCart, compare, addToCompare } =
    useGlobalStore();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [popularProducts, setPopularProducts] = useState([]);

  useEffect(() => {
    if (productData && productData.length > 0) {
      const shuffledProducts = productData.sort(() => 0.5 - Math.random());
      const selectedProducts = shuffledProducts.slice(0, 8);
      setPopularProducts(selectedProducts);
    }
  }, [productData]);

  const handleAddToCompare = (event, product) => {
    event.preventDefault();
    if (!isInCompare(product._id)) {
      addToCompare(product);
      toast.success("Product added to Compare");
    } else {
      toast.error("Product is already in Compare");
    }
  };

  const handleAddToCart = (product, selectedColor) => {
    const productWithColor = { ...product, color: selectedColor };
    if (!isInCart(product._id)) {
      addToCart(productWithColor);
      toast.success("Product added to Cart");
    } else {
      toast.error("Product is already in Cart");
    }
  };

  const handleAddToWishlist = (event, product) => {
    event.preventDefault();
    if (!isInWishlist(product._id)) {
      addToWishlist(product);
      toast.success("Product added to Wishlist");
    } else {
      toast.error("Product is already in Wishlist");
    }
  };

  const isInCompare = (productId) =>
    compare && compare.some((item) => item._id === productId);
  const isInCart = (productId) => cart.some((item) => item._id === productId);
  const isInWishlist = (productId) =>
    wishlist.some((item) => item._id === productId);

  const openModal = (event, product) => {
    event.preventDefault();
    setSelectedProduct(product);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  // Define custom arrows for Slider
  const PrevArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <i className="ion-ios-arrow-left"></i>
    </div>
  );

  const NextArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <i className="ion-ios-arrow-right"></i>
    </div>
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      // other breakpoints...
    ],
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  // Filter out `currentSlide` and `slideCount` props
  const sliderProps = { ...settings };
  delete sliderProps.currentSlide;
  delete sliderProps.slideCount;

  return (
    <div className="container">
      <div className="row pb-5">
        <div className="col-md-12 title text-center pt-3 pb-3">
          <h2 className="position-relative d-inline-block heading-h2">
            Featured Products
          </h2>
        </div>
        <Slider {...sliderProps}>
          {popularProducts.map((product) => (
            <div key={product._id} className="col-md-3">
              <ProductCard
                product={product}
                handleAddToCart={handleAddToCart}
                handleAddToCompare={handleAddToCompare}
                handleAddToWishlist={handleAddToWishlist}
                isInWishlist={isInWishlist}
                isInCompare={isInCompare}
                openModal={openModal}
              />
            </div>
          ))}
        </Slider>
      </div>
      {selectedProduct && (
        <ProductModal product={selectedProduct} closeModal={closeModal} />
      )}
    </div>
  );
};

export default Popular_Products;
