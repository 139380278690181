import Hero from "../../Components/Hero/Hero";
import Service from "../../Components/Service/Service";
import First_Banner from "../../Components/Banners/Homepage_Banner/First_Banner/First_Banner";
import Second_Banner from "../../Components/Banners/Homepage_Banner/Second_Banner/Second_Banner";
import Popular_Products from "../../Components/Popular_Products/Popular_Products";
import Newsletter from "../../Components/Newsletter/Newsletter";
import Footer from "../../Components/Footer/Footer";
import Navigation from "../../Components/Navigation/Navigation";
import FlashSale from "../../Components/FlashSale/FlashSale";
import Collection_Filter from "../../Components/Collection_Filter/Collection_Filter";

const Homepage = ({ productData, sliderData, service }) => {
  return (
    <div>
      <Navigation />
      <Hero sliderData={sliderData} />
      <Service service={service} />
      <Collection_Filter productData={productData} />
      <First_Banner />
      <FlashSale productData={productData} />
      <Second_Banner />
      <Popular_Products productData={productData} />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Homepage;
