const Service = ({ service }) => {
  return (
    <>
      <div className="py-5">
        <div className="container">
          <div className="row no-gutters">
            {service &&
              service.map((service) => (
                <div key={service._id} className="col-lg-4">
                  <div className="icon_box icon_box_style1">
                    <div className="icon">
                      <i className={service.icon}></i>
                    </div>
                    <div className="icon_box_content">
                      <h5>{service.title}</h5>
                      <p>{service.text}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
