import React from "react";

const BillingDetailsForm = ({
  userInfo,
  showShippingAddress,
  setShowShippingAddress,
  handleBillingInputChange,
  handleShippingInputChange,
  billingInfo,
  shippingInfo,
  errors,
  setShippingInfo,
}) => {
  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setShowShippingAddress(checked);

    // If shipping address is being shown, update shippingInfo with a copy of billingInfo
    if (checked) {
      setShippingInfo({ ...billingInfo });
    } else {
      // If shipping address is not being shown, reset shippingInfo
      setShippingInfo({
        shippingFirstName: "",
        shippingLastName: "",
        shippingCompanyName: "",
        shippingCountry: "",
        shippingAddress: "",
        shippingAddress2: "",
        shippingCity: "",
        shippingState: "",
        shippingZipcode: "",
        shippingPhone: "",
        shippingEmail: "",
      });
    }
  };

  return (
    <div className="col-md-6">
      <div className="heading_s1">
        <h4>Billing Details</h4>
      </div>
      <div className="form-group">
        <input
          type="text"
          required
          className="form-control"
          name="firstName"
          defaultValue={billingInfo.firstName}
          onChange={handleBillingInputChange}
          placeholder="First name *"
        />
        {errors.firstName && (
          <span className="showError">{errors.firstName}</span>
        )}
      </div>
      <div className="form-group">
        <input
          type="text"
          required
          className="form-control"
          name="lastName"
          defaultValue={billingInfo.lastName}
          onChange={handleBillingInputChange}
          placeholder="Last name *"
        />
        {errors.lastName && (
          <span className="showError">{errors.lastName}</span>
        )}
      </div>
      <div className="form-group">
        <input
          className="form-control"
          type="text"
          name="companyName"
          defaultValue={billingInfo.companyName}
          onChange={handleBillingInputChange}
          placeholder="Company Name"
        />
      </div>
      <div className="form-group">
        <input
          className="form-control"
          required
          type="text"
          name="country"
          defaultValue={billingInfo.country}
          onChange={handleBillingInputChange}
          placeholder="Country *"
        />
        {errors.country && <span className="showError">{errors.country}</span>}
      </div>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          name="address"
          defaultValue={billingInfo.address}
          onChange={handleBillingInputChange}
          required
          placeholder="Address *"
        />
        {errors.address && <span className="showError">{errors.address}</span>}
      </div>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          name="address2"
          defaultValue={billingInfo.address2}
          onChange={handleBillingInputChange}
          placeholder="Address line2"
        />
      </div>
      <div className="form-group">
        <input
          className="form-control"
          required
          type="text"
          name="city"
          defaultValue={billingInfo.city}
          onChange={handleBillingInputChange}
          placeholder="City / Town *"
        />
        {errors.city && <span className="showError">{errors.city}</span>}
      </div>
      <div className="form-group">
        <input
          className="form-control"
          required
          type="text"
          name="state"
          defaultValue={billingInfo.state}
          onChange={handleBillingInputChange}
          placeholder="State / County *"
        />
        {errors.state && <span className="showError">{errors.state}</span>}
      </div>
      <div className="form-group">
        <input
          className="form-control"
          required
          type="text"
          name="zipcode"
          defaultValue={billingInfo.zipcode}
          onChange={handleBillingInputChange}
          placeholder="Postcode / ZIP *"
        />
        {errors.zipcode && <span className="showError">{errors.zipcode}</span>}
      </div>
      <div className="form-group">
        <input
          className="form-control"
          required
          type="text"
          name="phone"
          defaultValue={billingInfo.phone}
          onChange={handleBillingInputChange}
          placeholder="Phone *"
        />
        {errors.phone && <span className="showError">{errors.phone}</span>}
      </div>
      <div className="form-group">
        <input
          className="form-control"
          required
          type="text"
          name="email"
          defaultValue={billingInfo.email}
          onChange={handleBillingInputChange}
          placeholder="Email address *"
        />
        {errors.email && <span className="showError">{errors.email}</span>}
      </div>
      {/* shipping address */}
      <div className="form-group">
        <div className="chek-form">
          <div className="custome-checkbox">
            <input
              className="form-check-input"
              type="checkbox"
              name="shipToDifferentAddress"
              id="differentaddress"
              checked={showShippingAddress}
              onChange={handleCheckboxChange}
            />
            <label
              className="form-check-label label_info"
              htmlFor="differentaddress"
            >
              <span style={{ marginLeft: "10px" }}>
                Ship to a different address?
              </span>
            </label>
          </div>
        </div>
      </div>

      {showShippingAddress && (
        <div className="ship_detail">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="shippingFirstName"
              defaultValue={shippingInfo.firstName}
              onChange={handleShippingInputChange}
              placeholder="Shipping First name *"
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="shippingLastName"
              defaultValue={shippingInfo.lastName}
              onChange={handleShippingInputChange}
              placeholder="Shipping Last name *"
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="shippingCompanyName"
              defaultValue={shippingInfo.companyName}
              onChange={handleShippingInputChange}
              placeholder="Shipping Company Name"
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="shippingCountry"
              defaultValue={shippingInfo.country}
              onChange={handleShippingInputChange}
              placeholder="Shipping Country *"
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="shippingAddress"
              defaultValue={shippingInfo.address}
              onChange={handleShippingInputChange}
              placeholder="Shipping Address *"
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="shippingAddress2"
              defaultValue={shippingInfo.address2}
              onChange={handleShippingInputChange}
              placeholder="Shipping Address line2"
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="shippingCity"
              defaultValue={shippingInfo.city}
              onChange={handleShippingInputChange}
              placeholder="Shipping City / Town *"
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="shippingState"
              defaultValue={shippingInfo.state}
              onChange={handleShippingInputChange}
              placeholder="Shipping State / County *"
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="shippingZipcode"
              defaultValue={shippingInfo.zipcode}
              onChange={handleShippingInputChange}
              placeholder="Shipping Postcode / ZIP *"
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="shippingPhone"
              defaultValue={shippingInfo.phone}
              onChange={handleShippingInputChange}
              placeholder="Shipping Phone *"
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="shippingEmail"
              defaultValue={shippingInfo.email}
              onChange={handleShippingInputChange}
              placeholder="Shipping Email address *"
            />
          </div>
        </div>
      )}

      <div className="heading_s1">
        <h4>Additional information</h4>
      </div>
      <div className="form-group mb-0">
        <textarea
          rows="5"
          className="form-control"
          name="orderNotes"
          defaultValue={billingInfo.orderNotes}
          onChange={handleBillingInputChange}
          placeholder="Order notes"
        ></textarea>
      </div>
    </div>
  );
};

export default BillingDetailsForm;
