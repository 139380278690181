import Footer from "../../Components/Footer/Footer";
import Newsletter from "../../Components/Newsletter/Newsletter";
import { Link } from "react-router-dom";
import Navigation from "../../Components/Navigation/Navigation";

const Success = () => {
  return (
    <>
      <Navigation />
      <div className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="text-center order_complete">
                <i className="fas fa-check-circle"></i>
                <div className="heading_s1">
                  <h3>Your order is completed!</h3>
                </div>
                <p>
                  Thank you for your order! Your order is being processed and
                  will be completed within 3-6 hours. You will receive an email
                  confirmation when your order is completed.
                </p>
                <Link to="/shoppage" className="btn btn-danger text-white">
                  Continue Shopping
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <Footer />
    </>
  );
};

export default Success;
