import React from "react";

const ProductHeaderLeft = ({ sortingOption, handleSortingChange }) => {
  return (
    <div className="product_header_left">
      <div className="custom_select">
        <select
          className="form-control form-control-sm"
          value={sortingOption}
          onChange={handleSortingChange}
        >
          <option value="order">Default sorting</option>
          <option value="date">Sort by newness</option>
          <option value="price">Sort by price: low to high</option>
          <option value="price-desc">Sort by price: high to low</option>
        </select>
      </div>
    </div>
  );
};

export default ProductHeaderLeft;
