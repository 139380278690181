import React from "react";

const SizeFilter = ({ selectedSizes, handleSizeSelection }) => {
  return (
    <div className="product_size_switch">
      {["XS", "S", "M", "L", "XL"].map((size) => (
        <span
          key={size}
          onClick={() => handleSizeSelection(size)}
          className={`product_size ${
            selectedSizes.includes(size) ? "selected" : ""
          }`}
        >
          {size}
        </span>
      ))}
    </div>
  );
};

export default SizeFilter;
