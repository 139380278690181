import React from "react";

const Pagination = ({ currentPage, totalPages, paginate }) => {
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  return (
    <ul className="pagination mt-3 justify-content-center pagination_style1">
      {pageNumbers.map((pageNumber) => (
        <li
          key={pageNumber}
          className={`page-item ${currentPage === pageNumber ? "active" : ""}`}
        >
          <a
            href="#"
            onClick={() => paginate(pageNumber)}
            className="page-link"
          >
            {pageNumber}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Pagination;
